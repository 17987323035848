import React from "react";
import { FormGroup, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
function JobCheckboxFilter({ checkedItems, options, handleChange }) {
  function isChecked(option) {
    if (checkedItems.length > 0) {
      const item = checkedItems.find((item) => option === item);
      if (item) return true;
    }
    return false;
  }
  return (
    <FormGroup sx={{ maxHeight: "200px", overflowY: "auto" }}>
      {options.map((option) => (
        <FormControlLabel
          key={option}
          control={<Checkbox checked={isChecked(option)} name={option} />}
          onChange={handleChange}
          label={option}
        />
      ))}
    </FormGroup>
  );
}

export default JobCheckboxFilter;
