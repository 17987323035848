import React from "react";
import { Snackbar, Alert } from "@mui/material";
function ResponseSnackbar({
  message,
  open,
  hideDuration = 3000,
  onClose,
  severity,
  name,
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
  variant = "filled"
}) {
  return (
    <Snackbar
      name={name}
      open={open}
      autoHideDuration={hideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert variant={variant} severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ResponseSnackbar;
