import { useMutation } from "@tanstack/react-query";
import { updateCandidateEmailStatus } from "app/api/candidate_api";

const useUpdateEmailStatus = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) => updateCandidateEmailStatus(params.id, params.status, params.email),
    onSuccess: onSuccess,
    onError: onError
  });
};

export default useUpdateEmailStatus;
