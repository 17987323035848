import { Send } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { QueryClient } from "@tanstack/react-query";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import FormAutocompleteMulti from "app/components/form-components/FormAutocompleteMulti";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import FormTextField from "app/components/form-components/FormTextField";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import { useGetCandidateResume } from "app/hooks/candidate/useGetCandidateResume";
import { useAddSkills } from "app/hooks/skill/useAddSkills";
import { useGetSkills } from "app/hooks/skill/useGetSkills";
import useAuth from "app/hooks/useAuth";
import {
  CityRegex,
  EmailRegex,
  filterAlphabets,
  NameRegex,
  PhoneRegex
} from "app/utils/global_constants";
import { Languages } from "app/utils/selection_constants";

import { isCandidateExists } from "app/api/candidate_api";
import { useGenerateOTP } from "app/hooks/otp/useGenerateOTP";
import {
  calculateAge,
  convertListToString,
  convertToBase64,
  createObjectURLFromFile,
  getCurrentTimeStamp,
  isFileLimitExceeded
} from "app/utils/utils";
import { QualificationOptions } from "app/views/jobs/Constants";
import AddNewSkillDialog from "app/views/jobs/shared/AddNewSkillDialog";
import ContentBox from "app/views/jobs/shared/ContentBox";
import dayjs from "dayjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { States } from "./Constants";
import EmailVerifyAdornment from "./EmailVerifyAdornment";
import ProfilePreviewDialog from "./ProfilePreviewDialog";
import UploadFileTitle from "./UploadFileTitle";
import VerifyEmailDialog from "./VerifyEmailDialog";
function AddCandidateForm({ mutationFn, preloadedData, title = "", mutationState }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity
      }
    }
  });
  const {
    control,
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    watch,
    getFieldState,
    getValues,
    formState: { errors }
  } = useForm();

  const { user } = useAuth();
  const location = useLocation();
  const isEditAction =
    location.pathname.includes("candidate/listings") || location.pathname.includes("/profile");

  const prev_yoe_value = watch("jp_yoe");
  const prev_email_value = watch("jp_email");
  let isVerified = 0;
  if (preloadedData) {
    if (prev_email_value === preloadedData.jp_email) {
      isVerified = preloadedData.jp_is_email_verified;
    } else {
      isVerified = 0;
    }
  } else {
    isVerified = 1;
  }

  const [resume, setResume] = useState();
  const [photo, setPhoto] = useState(preloadedData?.jp_photo);
  const previewPhoto =
    photo instanceof File
      ? createObjectURLFromFile(photo)
      : `data:image/jpeg;base64,${convertToBase64(photo?.data)}`;

  const [openSkillDialog, setOpenSkillDialog] = useState(false); // opens and closes 'Add Skill Dialoga
  const [openViewProfile, setOpenViewProfile] = useState(false); // Open and closes view profile picture dialog
  const [openVerifyEmail, setOpenVerifyEmail] = useState(false); // Open and closes verify email dialog

  const handleEmailBlur = async (role) => {
    const email = getValues("jp_email");
    if (role !== "SA") return;
    try {
      const isExists = await queryClient.fetchQuery({
        queryKey: ["is_candidate_exists"],
        queryFn: () => isCandidateExists(email)
      });
      if (isExists) {
        setError("jp_email", { type: "manual", message: "Email already exists" });
      } else {
        clearErrors("jp_email");
      }
    } catch (error) {
      console.log(console.error);
    }
  };

  // Skills that were saved in DB
  const preSkills = preloadedData
    ? preloadedData.Skills
      ? preloadedData.Skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill }))
      : []
    : [];

  const handleGenerateOTPSuccess = () => {
    clearErrors("jp_email");
    setOpenVerifyEmail(true);
  };

  const handleGenerateOTPError = () => {
    setError("jp_email", { type: "manual", message: "Too many attempts made to verify" });
  };

  // Generate OTP for verification
  const { mutate: generateOTP, isPending: isGenerating } = useGenerateOTP(
    handleGenerateOTPSuccess,
    handleGenerateOTPError
  );
  // Add new skills
  const { mutate: addSkill, isPending: isAdding, data: newSkills } = useAddSkills();

  const { data: dbResume } = useGetCandidateResume({
    id: preloadedData ? preloadedData.jp_id : undefined,
    onSuccessRequest: () => {},
    fileName: "resume.pdf"
  });

  // Get skill list
  const { data: skills } = useGetSkills({ keys: [newSkills] });

  const handleOpenVerifyOTPDialog = () => {
    const email = getValues("jp_email");
    const name = getValues("jp_candidate_name");
    generateOTP({ email, name, type: "reverify-email" });
  };

  const previewResume = (resume) => {
    let url;
    if (resume instanceof File) {
      url = URL.createObjectURL(resume);
    } else {
      const fileBlob = new Blob([resume], { type: "application/pdf" });
      const file = new File([fileBlob], "resume.pdf", { type: "application/pdf" });
      url = URL.createObjectURL(file);
    }
    window.open(url, "_blank");
    window.URL.revokeObjectURL(url);
  };

  // On photo upload
  const onPhotoUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 1024 })) {
      alert("File size limit of 1 MB exceeded");
      setError(
        "jp_photo",
        { type: "custom", message: "File size limit of 1 MB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors("jp_photo");
      setPhoto(file);
    }
  };

  // On resume upload
  const onResumeUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 1024 })) {
      alert("File size limit of 1MB exceeded");
      setError(
        "jp_resume",
        { type: "custom", message: "File size limit of 1MB exceeded" },
        { shouldFocus: true }
      );
      setResume(null);
    } else {
      clearErrors("jp_resume");
      setResume(file);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (preloadedData && (preloadedData.jp_resume || preloadedData.jp_photo)) {
      clearErrors(["jp_resume", "jp_photo"]);
    }

    if (isVerified === 0) {
      setError("jp_email", { type: "manual", message: "Please verify email" });
      return;
    }

    handleSubmit(onSubmitData)(event);
  };

  const onSubmitData = (data, event) => {
    const formData = new FormData(event.target);
    formData.set("jp_state", data.jp_state.label || data.jp_state);
    formData.set("jp_qualification", data.jp_qualification.label || data.jp_qualification);
    formData.set("jp_dob", data.jp_dob.toISOString());
    formData.set("jp_skills", convertListToString(data.jp_skills));
    formData.set("jp_language", convertListToString(data.jp_language));
    formData.set("jp_passingyear", data.jp_passingyear.toISOString());
    formData.set("jp_yoe", parseFloat(data.jp_yoe));
    formData.set("jp_active", true);

    if (preloadedData && !preloadedData.jp_resume) {
      formData.append("jp_resume", resume);
    }

    if (preloadedData && !preloadedData.jp_photo) {
      formData.append("jp_photo", photo);
    }

    // For update operation jp_updated_at and jp_udpated_by fields are updated
    if (
      location.pathname.includes("/candidate/listings") ||
      location.pathname.includes("/profile")
    ) {
      // We do not want to update candidate's existing password when updating hence delete field
      formData.delete("jp_password");
      formData.set("jp_updated_at", getCurrentTimeStamp());
      formData.set("jp_updated_by", user.id);
    }

    // For new candidates added, jp_created_by and jp_created_at are updated
    if (location.pathname.includes("/candidate/addCandidate")) {
      // Setting a default password when HR adds a new candidate
      formData.set("jp_password", "Password123$");
      formData.append("jp_created_by", user.id);
      formData.append("jp_yoe", data.jp_yoe);
      formData.append("jp_created_at", getCurrentTimeStamp());
    }
    mutationFn({ data: formData });
  };

  const actionButtonText = location.pathname.includes("/candidate/listings") ? "Update" : "Submit";
  let shouldPreviewResume = false;
  if ((resume && resume.size > 0) || (dbResume && dbResume.size > 0)) {
    shouldPreviewResume = true;
  }

  return (
    <ContentBox>
      <VerifyEmailDialog
        open={openVerifyEmail}
        onClose={() => setOpenVerifyEmail(false)}
        email={getValues("jp_email")}
      />
      <AddNewSkillDialog
        open={openSkillDialog}
        onClose={() => setOpenSkillDialog(false)}
        addFn={addSkill}
        loading={isAdding}
      />
      {/** View Profile Picture Dialog */}
      <ProfilePreviewDialog
        onClose={() => setOpenViewProfile(false)}
        open={openViewProfile}
        profile={previewPhoto}
      />
      <h3>{title}</h3>
      <form onSubmit={onSubmit} encType="multipart/form-data">
        <Grid container>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box display={"flex"} gap={4}>
              <Box width={"100%"}>
                <UploadFileTitle
                  title={"Upload Resume *"}
                  actionTitle={isEditAction ? "View" : resume ? "Preview" : ""}
                  shouldPreview={shouldPreviewResume}
                  previewClick={() => {
                    if (preloadedData || dbResume || resume) {
                      if (resume) {
                        previewResume(resume);
                      } else if (dbResume) {
                        previewResume(dbResume);
                      }
                    }
                  }}
                />
                <FormUploadFile
                  name={"jp_resume"}
                  fileType={"application/pdf"}
                  errors={errors.jp_resume}
                  file={resume}
                  register={register}
                  preloadedData={preloadedData ? preloadedData.jp_resume : undefined}
                  helperText={"Please upload your resume"}
                  onUploadFile={onResumeUpload}
                />
              </Box>
              <Box width={"100%"}>
                <UploadFileTitle
                  title={"Upload Photo *"}
                  actionTitle={isEditAction ? "View" : photo ? "Preview" : ""}
                  shouldPreview={photo}
                  previewClick={() => setOpenViewProfile(true)}
                />
                <FormUploadFile
                  name={"jp_photo"}
                  fileType={"image/*"}
                  errors={errors.jp_photo}
                  file={photo}
                  register={register}
                  helperText={"Please upload your profile picture"}
                  onUploadFile={onPhotoUpload}
                  preloadedData={preloadedData ? preloadedData.jp_photo : undefined}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h3>Basic Details</h3>
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display={"flex"} gap={4}>
                <FormTextField
                  name="jp_candidate_name"
                  control={control}
                  label={"Full Name *"}
                  defaultValue={preloadedData ? preloadedData.jp_candidate_name : ""}
                  fullWidth={true}
                  rules={{ pattern: { value: NameRegex, message: "Invalid name format" } }}
                  helperText={"Full name is required"}
                />
                <FormTextField
                  name="jp_email"
                  control={control}
                  label={"Email * "}
                  defaultValue={preloadedData ? preloadedData.jp_email : ""}
                  fullWidth={true}
                  type={"email"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailVerifyAdornment
                          isVerified={isVerified}
                          isGenerating={isGenerating}
                          onClick={handleOpenVerifyOTPDialog}
                        />
                      </InputAdornment>
                    )
                  }}
                  onBlur={() => handleEmailBlur(user.role)}
                  rules={{ pattern: { value: EmailRegex, message: "Invalid email format" } }}
                  helperText={"Email is required"}
                />
              </Box>

              <Box display="flex" gap={4}>
                <FormDatePicker
                  name="jp_dob"
                  label={"D.O.B * "}
                  defaultValue={preloadedData ? preloadedData.jp_dob : ""}
                  fullWidth={true}
                  rules={{
                    validate: (fieldValue) => {
                      if (dayjs.isDayjs(fieldValue) && isNaN(fieldValue.$d.getTime())) {
                        return "Date of Birth is required";
                      }
                      if (!dayjs(fieldValue).isValid()) {
                        return "Invalid date of birth";
                      }
                      return true;
                    }
                  }}
                  control={control}
                  disableFuture
                  helperText={"Date of Birth is required"}
                  handleOnChange={(value) => {
                    setValue("jp_age", calculateAge(value.$d));
                  }}
                />
                <FormTextField
                  name="jp_age"
                  control={control}
                  label={"Age *"}
                  fullWidth={true}
                  defaultValue={preloadedData ? preloadedData.jp_age : ""}
                  type={"number"}
                  rules={{ min: { value: 1, message: "Please enter valid age" } }}
                  InputLabelProps={{ shrink: true }}
                  onInput={filterAlphabets}
                  helperText={"Age is required"}
                />
              </Box>
              <Box display={"flex"} gap={4}>
                <FormTextField
                  name="jp_designation"
                  control={control}
                  label={"Designation"}
                  defaultValue={preloadedData ? preloadedData.jp_designation : ""}
                  fullWidth={true}
                />
                <FormTextField
                  name="jp_contactno"
                  control={control}
                  label={"Phone Number *"}
                  defaultValue={preloadedData ? preloadedData.jp_contactno : ""}
                  fullWidth={true}
                  type={"text"}
                  rules={{
                    pattern: { value: PhoneRegex, message: "Please enter valid phone number" }
                  }}
                  onInput={filterAlphabets}
                  inputProps={{ maxLength: 10 }}
                  helperText={"Phone Number is required"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h3>Address</h3>
            <Box display="flex" flexDirection="column" gap={3}>
              <FormTextField
                name="jp_address"
                control={control}
                label={"Address street, house no., etc *"}
                defaultValue={preloadedData ? preloadedData.jp_address : ""}
                fullWidth={true}
                helperText={"Address is required"}
              />
              <Box display="flex" gap={4}>
                {/* City */}
                <FormTextField
                  name="jp_city"
                  control={control}
                  label={"City *"}
                  defaultValue={preloadedData?.jp_city}
                  fullWidth={true}
                  rules={{ pattern: { value: CityRegex, message: "Please enter valid city" } }}
                  helperText={"City name is required"}
                />
                {/* State */}
                <FormAutocomplete
                  name="jp_state"
                  control={control}
                  label={"State *"}
                  values={States}
                  defaultValue={preloadedData?.jp_state}
                  sxValues={{ width: "100%" }}
                  helperText={"State is required"}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h3>Qualifications</h3>
            <Box display="flex" gap={4}>
              <FormAutocomplete
                name="jp_qualification"
                control={control}
                label={"Highest Qualification Level *"}
                values={QualificationOptions}
                sxValues={{ flexGrow: 1 }}
                defaultValue={preloadedData ? preloadedData.jp_qualification : ""}
                helperText={"Qualification is required"}
              />
              <FormTextField
                name="jp_degree_name"
                control={control}
                label={"Specialization"}
                sx={{ flexGrow: 1 }}
                defaultValue={preloadedData ? preloadedData.jp_degree_name : ""}
              />

              <FormDatePicker
                name={"jp_passingyear"}
                label={"Graduation Date *"}
                defaultValue={preloadedData ? preloadedData.jp_passingyear : ""}
                control={control}
                rules={{
                  validate: (fieldValue) => {
                    if (dayjs.isDayjs(fieldValue) && isNaN(fieldValue.$d.getTime())) {
                      return "Graduation date is required";
                    }
                    if (!dayjs(fieldValue).isValid()) {
                      return "Invalid graduation date";
                    }
                    return true;
                  }
                }}
                helperText={"Graduation date is required"}
                disableFuture
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h3>Current Employment Details</h3>
            <Box display={"flex"} gap={3}>
              {/* Current Company */}
              <FormTextField
                name="jp_current_company"
                control={control}
                label={"Current Company"}
                sx={{ flex: 1 }}
                rules={{
                  pattern: { value: CityRegex, message: "Please enter valid company name" }
                }}
                defaultValue={preloadedData ? preloadedData.jp_current_company : ""}
              />
              {/* CTC */}
              <FormTextField
                name={"jp_current_ctc"}
                label={"Current CTC"}
                defaultValue={preloadedData ? preloadedData.jp_current_ctc : ""}
                sx={{ flex: 1 }}
                rules={{
                  pattern: {
                    value: /^\d+$/,
                    message: "Please enter valid CTC"
                  }
                }}
                control={control}
              />
              {/* Notice Period */}
              <FormTextField
                name={"jp_notice_period"}
                label={"Notice Period (Mention in days)"}
                defaultValue={preloadedData ? preloadedData.jp_notice_period : ""}
                control={control}
                type={"number"}
                sx={{ flex: 1 }}
                onInput={filterAlphabets}
                rules={{
                  min: {
                    value: 0,
                    message: "Please enter a valid value"
                  }
                }}
              />
              {/* Years of Experience */}
              <FormTextField
                name={"jp_yoe"}
                label={"Years of Experience"}
                defaultValue={preloadedData ? preloadedData.jp_yoe : "0"}
                control={control}
                rules={{
                  min: { value: 0, message: "Please enter YOE greater than or equal to 0" }
                }}
                sx={{ flex: 1 }}
                onInput={(event) => {
                  const regex = new RegExp(/^\d*\.?\d*$/);
                  if (!regex.test(event.target.value)) {
                    event.target.value = prev_yoe_value;
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <h3>Skills & Language</h3>
              <Button
                variant={"outlined"}
                endIcon={<Add />}
                size="small"
                onClick={() => setOpenSkillDialog(true)}
                style={{ paddingY: 0, maxHeight: "30px" }}
              >
                <Typography variant="body2">Add Skill</Typography>
              </Button>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <Stack direction={"row"} gap={4} flexGrow={1}>
                <FormAutocompleteMulti
                  name="jp_skills"
                  control={control}
                  label={"Skills *"}
                  defaultValue={preSkills}
                  isOptionEqualToValue={(option, value) => {
                    return option.jp_id === value.jp_id;
                  }}
                  values={
                    skills
                      ? skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill }))
                      : [{ jp_id: 0, label: "Loading..." }]
                  }
                  sx={{ width: "100%" }}
                  helperText={"Skills is required"}
                />
                <FormAutocompleteMulti
                  name="jp_language"
                  control={control}
                  label={"Languages Spoken *"}
                  defaultValue={preloadedData ? preloadedData.jp_language : ""}
                  values={Languages}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  sx={{ flexGrow: 1 }}
                  helperText={"Language is required"}
                />
              </Stack>
              <FormTextField
                name={"jp_about_me"}
                label={
                  user.role === "SA"
                    ? "Add description of candidate *"
                    : "Add a description about yourself *"
                }
                defaultValue={preloadedData ? preloadedData.jp_about_me : ""}
                control={control}
                helperText={"Candidate description is required"}
                multiline
                fullWidth
                rows={10}
                sx={{ mb: 4 }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <LoadingButton
            loadingPosition="start"
            loading={mutationState}
            color="primary"
            variant="contained"
            type="submit"
            startIcon={<Send />}
          >
            {actionButtonText}
          </LoadingButton>
          {errors && Object.keys(errors).length !== 0 && (
            <Typography color="red">Please recheck form</Typography>
          )}
        </Box>
      </form>
    </ContentBox>
  );
}

export default AddCandidateForm;
