import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Info, Verified } from "@mui/icons-material";
function EmailVerifyAdornment({ isVerified, isGenerating, onClick }) {
  return (
    <Box>
      {Number(isVerified) === 1 ? (
        <Tooltip title={"Email Verified"} sx={{ cursor: "pointer" }}>
          <Verified color={"success"} />
        </Tooltip>
      ) : (
        <LoadingButton
          loading={isGenerating}
          variant="contained"
          onClick={onClick}
          sx={{ display: "flex", gap: 1 }}
        >
          <Info />
          <Typography variant="body1">Verify Email</Typography>
        </LoadingButton>
      )}
    </Box>
  );
}

export default EmailVerifyAdornment;
