import { City, State } from "country-state-city";

export const CityStateAction = {
  SET_STATE: "SET_STATE",
  SET_CITY: "SET_CITY",
  UPDATE_CITY: "UPDATE_CITY",
  UPDATE_STATE: "UPDATE_STATE"
};

export function cityStateReducer(stateValue, action) {
  const { country, states, cities } = action.payload;
  switch (action.type) {
    case CityStateAction.SET_CITY:
      return { ...stateValue, cities: cities.map((city) => city.label).join("| ") };

    // Updated Cities
    case CityStateAction.UPDATE_CITY:
      if (stateValue.cities === null) return stateValue;

      const citiesOfSelectedStates = [];
      states.forEach((state) =>
        citiesOfSelectedStates.push(...City.getCitiesOfState(state.countryCode, state.id))
      );

      const currentSelectedCities = stateValue.cities.split("| ");
      const newCities = currentSelectedCities.filter((currentCity) => {
        const foundCity = citiesOfSelectedStates.find((city) => {
          return `${city.stateCode}-${city.name}` === currentCity;
        });
        if (foundCity) {
          return true;
        }
        return false;
      });
      return { ...stateValue, cities: newCities.join("| ") };

    // State
    case CityStateAction.SET_STATE:
      return { ...stateValue, states: states.map((state) => state.label).join("| ") };

    // Update State
    case CityStateAction.UPDATE_STATE:
      if (stateValue.states === null) return stateValue;

      const statesOfSelectedCountries = [];
      const citiesOfStates = [];

      country.forEach((country) => {
        statesOfSelectedCountries.push(...State.getStatesOfCountry(country.id));
      });

      const currentSelectedStates = stateValue.states.split("| ");

      // remove from the currentSelectedStates list, states that are not part of the countries selected
      const newStates = currentSelectedStates.filter((currentState) => {
        const findState = statesOfSelectedCountries.find((countryState) => {
          return countryState.name === currentState;
        });
        if (findState !== undefined) {
          citiesOfStates.push(...City.getCitiesOfState(findState.countryCode, findState.isoCode));
          return true;
        }
        return false;
      });

      const selectedCities = stateValue.cities.split("| ");
      const filteredCities = selectedCities.filter(
        (selectedCity) =>
          citiesOfStates.find((city) => {
            return `${city.stateCode}-${city.name}` === selectedCity;
          }) !== undefined
      );

      return { ...stateValue, states: newStates.join("| "), cities: filteredCities.join("| ") };
    default:
      return stateValue;
  }
}
