import React from "react";
import { Box, Typography } from "@mui/material";
import GrayBGText from "./GrayBGText";
import { TextBGColor } from "../JobList";
function JobCities({ cities }) {
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={1}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {cities &&
          cities.map((loc) => (
            <Typography
              key={loc}
              sx={{
                color: "gray",
                backgroundColor: TextBGColor,
                borderRadius: "2px",
                padding: "2px 4px"
              }}
            >
              {loc}
            </Typography>
          ))}
      </Box>
      {/* More cities text */}
      <Box sx={{ display: { xs: "flex", md: "none" }, gap: 1, alignItems: "center" }}>
        {cities && <GrayBGText loc={`${cities[0]} `} />}
        {cities && cities.length > 1 && <GrayBGText loc={`+${cities.length - 1}`} />}
      </Box>
    </>
  );
}

export default JobCities;
