import useAuth from "app/hooks/useAuth";
import { useMemo } from "react";
import { formatExp } from "app/utils/global_constants";
export const useExtractJobData = (jobData, appliedJobs) => {
  const { user } = useAuth();
  return useMemo(() => {
    let extractedData;
    if (user.role === "SA") {
      // Extracting data points for Admin and Candidate
      extractedData = jobData.map(
        ({ jp_id, jp_company_name, jp_title, jp_created_at, jp_type, jp_min_exp, jp_max_exp }) => {
          const exp =
            jp_min_exp && jp_max_exp
              ? `${formatExp(jp_min_exp)} - ${formatExp(jp_max_exp)} yrs`
              : "Fresher";
          return {
            jp_id,
            jp_company_name,
            jp_title,
            jp_created_at,
            jp_type,
            jp_experience: exp
          };
        }
      );
    } else if (user.role === "candidate") {
      extractedData = jobData.map(
        ({ jp_id, jp_company_name, jp_title, jp_created_at, jp_type, jp_min_exp, jp_max_exp }) => {
          const exp =
            jp_min_exp && jp_max_exp
              ? `${formatExp(jp_min_exp)} - ${formatExp(jp_max_exp)} yrs`
              : "Fresher";
          return {
            jp_id,
            jp_company_name,
            jp_title,
            jp_created_at,
            jp_type,
            jp_experience: exp,
            jp_is_applied: false
          };
        }
      );
      // Marking those jobs that the candidate has already applied for
      extractedData = extractedData.map((job) => {
        if (appliedJobs.find((apply) => job.jp_id === apply.jp_jobpost_id)) {
          return { ...job, jp_is_applied: true };
        } else {
          return job;
        }
      });
    }
    return extractedData;
  }, [user, jobData, appliedJobs]);
};
