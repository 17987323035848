import { Box, Typography } from "@mui/material";
import useGetCompanyLogo from "app/hooks/company/useGetCompanyLogo";
import { convertToBase64, getInitialsOfName } from "app/utils/utils";

const SquareImage = ({ companyId, companyName }) => {
  const { data: logo } = useGetCompanyLogo({ companyId });
  return (
    <div>
      {logo?.jp_logo && (
        <img
          src={`data:image/jpeg;base64,${convertToBase64(logo?.jp_logo?.data)}`}
          width={"50px"}
          height={"50px"}
        />
      )}
      {!logo?.jp_logo && (
        <Box
          sx={{
            cursor: "pointer",
            bgcolor: "#222a45",
            width: "50px",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px"
          }}
        >
          <Typography variant={"h6"}>{getInitialsOfName(companyName)}</Typography>
        </Box>
      )}
    </div>
  );
};

export default SquareImage;
