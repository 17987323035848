import useAuth from "app/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children, authRoles, isLandingPage }) {
  const { user } = useAuth();
  const location = useLocation();
  if (!user) {
    if (location.pathname === "/applications/current/sa") {
      return <Navigate to="/session/login/admin" />;
    }
    return <Navigate to="/" />;
  }

  if (user && user.role.toUpperCase() === authRoles.admin) {
    return children;
  }

  if (user && authRoles.includes(user.role.toUpperCase())) {
    return children;
  } else {
    return <h1>Cannot access this page</h1>;
  }
}

export default ProtectedRoute;
