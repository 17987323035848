import { Typography } from "@mui/material";
import { capitalizeFirstLetter } from "app/utils/utils";
import React from "react";
function StatusText({ status }) {
  let color = "inherit"; // Default color
  let bgColor = "inherit";
  if (status === "rejected") {
    color = "red";
    bgColor = "#ef9a9a";
  } else if (status === "pending") {
    color = "gray";
    bgColor = "lightgray";
  } else if (status === "accepted") {
    color = "green";
    bgColor = "lightGreen";
  } else if (status === "shortlisted") {
    color = "#2196f3";
    bgColor = "lightblue";
  }
  return (
    <Typography
      color={color}
      bgcolor={bgColor}
      px={2}
      py={1}
      fontWeight={"bold"}
      borderRadius={2}
      variant="body1"
      width="120px"
      textAlign={"center"}
    >
      {capitalizeFirstLetter(status)}
    </Typography>
  );
}

export default StatusText;
