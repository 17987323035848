export const initialState = {
  sortOrder: "DESC", // 'asc' or 'desc'
  limit: 20, // Number of items per page
  offset: 0, // Starting index for pagination
  column: "",
  page: 0, // Current page number
  searchTerm: "" // Search term for filtering
};
export const actionTypes = {
  SET_SORT_ORDER: "SET_SORT_ORDER",
  SET_LIMIT: "SET_LIMIT",
  SET_OFFSET: "SET_OFFSET",
  SET_COLUMN: "SET_COLUMN",
  SET_PAGE: "SET_PAGE",
  SET_SEARCH_TERM: "SET_SEARCH_TERM"
};

export function paginationReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_SORT_ORDER:
      return { ...state, sortOrder: state.sortOrder === "ASC" ? "DESC" : "ASC" };
    case actionTypes.SET_COLUMN:
      return { ...state, column: action.payload };
    case actionTypes.SET_LIMIT:
      return { ...state, limit: action.payload, page: 0 };
    case actionTypes.SET_OFFSET:
      return { ...state, offset: action.payload * state.limit };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionTypes.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    default:
      return state;
  }
}
