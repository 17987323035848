import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import JobDetailTitle from "./JobDetailTitle";
import JobDetailWrapper from "./JobDetailWrapper";
import { LocationCityRounded } from "@mui/icons-material";

function JobLocationType({ locationType }) {
  return (
    <JobDetailWrapper>
      <LocationCityRounded />
      <Box>
        <JobDetailTitle>Location Type</JobDetailTitle>
        {locationType ? (
          <Typography color={"gray"}>{locationType}</Typography>
        ) : (
          <Typography>No location specified</Typography>
        )}
      </Box>
    </JobDetailWrapper>
  );
}

export default JobLocationType;
