import { addSkills } from "app/api/skill_api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useAddSkills = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => {
      return addSkills(params);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["get_skills"] })
  });
};
