import React from "react";
import { Box } from "@mui/material";
import ImageBG from "../../../../assets/illustrations/bg-landing-page.png";
import ImageBG2 from "../../../../assets/illustrations/bg-landing-page2.png";
function LandingPageBG(props) {
  return (
    <Box
      width={"100%"}
      height={"400px"}
      zIndex={0}
      position={"absolute"}
      top={0}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#EEEEEE",
        opacity: { xs: 0.8, md: 0.8 },
        backgroundSize: "20px 20px"
      }}
    >
      <Box
        component={"img"}
        src={ImageBG}
        height={"400px"}
        sx={{ display: { xs: "none", md: "block" }, objectFit: "fill", marginLeft: "0px" }}
      ></Box>
      <img src={ImageBG2} height={"400px"} style={{ objectFit: "fill", marginLeft: "0px" }}></img>
    </Box>
  );
}

export default LandingPageBG;
