import { Place } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDebounce } from "app/hooks/useDebounce";
function FilterByCity({ onChange }) {
  const [city, setCity] = useState("");
  const debounceLocation = useDebounce(city, 500);

  useEffect(() => {
    onChange(debounceLocation);
  }, [debounceLocation]);
  return (
    <Box>
      <TextField
        label={"Search by city"}
        value={city}
        onChange={(event) => {
          setCity(event.target.value);
        }}
        size={"small"}
        sx={{ minWidth: { xs: "100%", md: "300px" } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Place />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default FilterByCity;
