import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
function SearchSection({ handleSearchClick }) {
  const [search, setSearch] = useState();
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchClick(search);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      handleSearchClick("");
    }
    setSearch(value);
  };

  return (
    <Box width={"100%"}>
      <Box sx={{ padding: { xs: "0px", md: "50px" } }} width={"100%"}>
        <Typography
          sx={{
            typography: {
              xs: "h5", // Use h3 on xs
              md: "h5" // Use h1 on md and up
            },
            fontWeight: 700
          }}
          position={"relative"}
          textAlign={"center"}
        >
          <strong>Find your dream job here</strong>
        </Typography>
        <Typography
          sx={{
            typography: {
              xs: "subtitle2", // Use h3 on xs
              md: "h6" // Use h1 on md and up
            }
          }}
          fontWeight={"bold"}
          position={"relative"}
          textAlign={"center"}
        >
          Browse over 100,000 jobs from top companies
        </Typography>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          <TextField
            placeholder={"Search by keyword"}
            fullWidth
            margin="normal"
            onChange={handleSearchChange}
            onKeyDown={handleKeyPress}
            sx={{
              borderRadius: "20px",
              width: { xs: "90%", md: "50%" },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "white", // For outlined variant
                borderRadius: "50px" // Applies to the outlined variant
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant={"contained"}
                    sx={{ borderRadius: "50px" }}
                    onClick={() => handleSearchClick(search)}
                  >
                    Search
                  </Button>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SearchSection;
