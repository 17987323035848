import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

const ChangeLogin = styled("p")({
  marginTop: "5px",
  textAlign: "center",
  width: "100%"
});
function ChangeLoginLink(props) {
  const location = useLocation();

  let linkText;
  let link;
  if (location.pathname.includes("candidate")) {
    linkText = "Are you a company? Login ";
    link = "/session/login/company";
  }

  if (location.pathname.includes("company")) {
    linkText = "Are you a candidate? Login ";
    link = "/session/login/candidate";
  }
  return (
    <ChangeLogin>
      {linkText}{" "}
      <Link to={link} style={{ color: "rgb(25, 118, 210)", textAlign: "center" }}>
        here
      </Link>
    </ChangeLogin>
  );
}

export default ChangeLoginLink;
