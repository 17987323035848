import { Typography } from "@mui/material";
function JobDetailTitle({ children }) {
  return (
    <Typography fontWeight={"bold"} variant="subtitle2">
      {children}
    </Typography>
  );
}

export default JobDetailTitle;
