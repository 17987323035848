import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Box, Button, Typography } from "@mui/material";
import { H2, H3, H4 } from "app/components/Typography";
import StatusText from "app/components/StatusText";
import JobDetailDialog from "app/views/jobs/shared/JobDetailDialog";
import useGetJob from "app/hooks/job/useGetJob";
import { PriorityHigh } from "@mui/icons-material";
import dayjs from "dayjs";
function AppliedJobCard({ jobId, jobTitle, companyName, appliedOn, status, isDeleted }) {
  const [detailOpen, setDetailOpen] = useState(false);
  const { data: job } = useGetJob(jobId);
  const dateAppliedOn = dayjs(appliedOn).format("DD/MM/YYYY");
  return (
    <Card sx={{ width: "100%", color: "black" }}>
      <CardContent>
        {job && (
          <JobDetailDialog
            open={detailOpen}
            jobPosting={job}
            handleClose={() => setDetailOpen(false)}
          />
        )}
        <Box display="flex" flexDirection={"column"} justifyContent={"flex-start"} gap={2}>
          <Box>
            <Box display="flex" gap={1} justifyContent={"space-between"}>
              <H2>{jobTitle.trim()}</H2>
              <StatusText status={status} />
            </Box>
            {isDeleted ? (
              <Box display={"flex"} my={1}>
                <PriorityHigh sx={{ height: "20px", color: "#ff9800" }} />
                <Typography color={"#ff9800"}>
                  The position you applied for is no longer available. Please check other
                  opportunities
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <H4 marginBottom={2}>{companyName}</H4>
            <Typography>Applied On: {dateAppliedOn}</Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={() => setDetailOpen(true)} variant="outlined">
          View Details
        </Button>
      </CardActions>
    </Card>
  );
}

export default AppliedJobCard;
