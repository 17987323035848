import { Box, styled, Button } from "@mui/material";
import { useState } from "react";

const PointerSpan = styled("span")({
  cursor: "pointer"
});
function ReadMoreHTML({
  className,
  text,
  maxChars,
  readMoreText = "read more",
  readLessText = "read less",
  ...rest
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasReadMore = text ? (text.length > maxChars ? true : false) : false;
  return (
    <Box>
      {text && (isExpanded || !hasReadMore) && (
        <Box component={"div"} dangerouslySetInnerHTML={{ __html: text }} {...rest} />
      )}
      {hasReadMore && !!isExpanded && (
        <Button onClick={() => setIsExpanded(false)}>
          <strong>{readLessText}</strong>
        </Button>
      )}
      {text && !isExpanded && !!hasReadMore && (
        <Box>
          <Box
            component={"div"}
            dangerouslySetInnerHTML={{ __html: `${text.slice(0, maxChars)}...` }}
            {...rest}
          />
          <Button onClick={() => setIsExpanded(true)}>
            <strong>{readMoreText}</strong>
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default ReadMoreHTML;
