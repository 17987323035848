import React from "react";
import AccordianFilter from "./AccordianFilter";
import SalaryRange from "./SalaryRange";
import { Box, Typography } from "@mui/material";
import JobCheckboxFilter from "./JobCheckboxFilter";
import FilterByExp from "app/views/jobs/shared/Job Filters/FilterByExp";
import { EmploymentTypes } from "app/views/jobs/Constants";
import { LocationOptions } from "app/views/jobs/Constants";
function getValueText(value) {
  return `${value}lakhs`;
}
function FilterWrapper({
  empType,
  locType,
  salary,
  exp,
  handleJobTypeChange,
  handleSalaryChange,
  handleLocationChange,
  hanldeExpChange
}) {
  return (
    <>
      <AccordianFilter
        defaultExpanded
        title={"Filter by Job Type"}
        filter={
          <JobCheckboxFilter
            checkedItems={empType}
            options={EmploymentTypes.map((type) => type.label)}
            handleChange={handleJobTypeChange}
          />
        }
      />
      <SalaryRange value={salary} handleChange={handleSalaryChange} valuetext={getValueText} />
      <AccordianFilter
        defaultExpanded
        title={"Filter by Location Type"}
        filter={
          <JobCheckboxFilter
            checkedItems={locType}
            handleChange={handleLocationChange}
            options={LocationOptions.map((loc) => loc.label)}
          />
        }
      />

      <Box px={2}>
        <Typography variant="subtitle2" mb={4}>
          Filter By Experience
        </Typography>
        <FilterByExp value={exp} onChecked={hanldeExpChange} />
      </Box>
    </>
  );
}

export default FilterWrapper;
