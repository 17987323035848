import React from "react";
import { Box, Typography } from "@mui/material";
function SalaryText({ minSalary, maxSalary }) {
  return (
    <Box display={"flex"} gap={1}>
      <Typography>Rs. {Number(minSalary).toLocaleString("en-in")}</Typography>
      <Box component={"span"}>-</Box>
      <Typography>Rs. {Number(maxSalary).toLocaleString("en-in")}</Typography>
    </Box>
  );
}

export default SalaryText;
