import React from "react";
import useAuth from "app/hooks/useAuth";
import { Navigate } from "react-router-dom";
import LandingPage from "app/views/landing_page/LandingPage";
function RedirectOnRole(props) {
  const { user } = useAuth();

  // Define your role-based redirect logic
  if (user?.role === "SA" || user?.role === "company") {
    return <Navigate to="/dashboard/default" replace />;
  } else if (user?.role === "guest" || user?.role === "candidate") {
    return <LandingPage />;
  }
  // Default case (optional)
  return <LandingPage />;
}

export default RedirectOnRole;
