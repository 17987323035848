import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";
import "./EditorStyles.css";
import "quill/dist/quill.snow.css";
const QuillEditor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange, onChange }, ref) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(container.ownerDocument.createElement("div"));
      const quill = new Quill(editorContainer, {
        theme: "snow"
      });

      ref.current = quill;

      if (defaultValue) {
        quill.setContents(quill.clipboard.convert({ html: defaultValue }));
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onChange(quill.root.innerHTML);
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    return <div id="#editor" className="quill-editor" ref={containerRef}></div>;
  }
);

export default QuillEditor;
