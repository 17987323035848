import React from "react";
import { Box, FormGroup, FormControlLabel, IconButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Close } from "@mui/icons-material";
function PopoverContent({ value, options, onChange, closePopup, title }) {
  return (
    <Box display={"flex"} flexDirection={"column"} py={1} px={2}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <h4>{title}</h4>
        <IconButton
          onClick={() => {
            closePopup();
          }}
          size="small"
          sx={{ width: "30px", height: "30px" }}
        >
          <Close />
        </IconButton>
      </Box>
      <FormGroup>
        {options.map((item) => {
          return (
            <FormControlLabel
              key={item.value}
              label={item.label}
              control={
                <Checkbox
                  checked={value.includes(item.value)}
                  value={item.value}
                  onChange={(event) => {
                    onChange(event);
                  }}
                />
              }
            />
          );
        })}
      </FormGroup>
    </Box>
  );
}

export default PopoverContent;
