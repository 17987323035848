import { useQuery } from "@tanstack/react-query";
import { getCandidateApplications } from "app/api/application_api";
const useGetCandidateApplication = ({ id, jobPostId, debounceSearch, status, enabled }) => {
  return useQuery({
    queryKey: ["CandidateApplication", id, debounceSearch, status],
    queryFn: () => {
      return getCandidateApplications(
        id,
        jobPostId,
        debounceSearch,
        status.map((status) => status.value).join(",")
      );
    }
  });
};

export default useGetCandidateApplication;
