import useAuth from "app/hooks/useAuth";
import { useMemo } from "react";
import { formatExp } from "app/utils/global_constants";
export const useExtractCompanyJobs = (jobData) => {
  const { user } = useAuth();
  return useMemo(() => {
    let extractedData = [];

    if (jobData.length > 0) {
      // Extracting data points for company
      extractedData = jobData.map(
        ({ jp_id, jp_title, jp_created_at, jp_type, jp_min_exp, jp_max_exp }) => {
          const exp =
            jp_min_exp && jp_max_exp
              ? `${formatExp(jp_min_exp)} - ${formatExp(jp_max_exp)} yrs`
              : "Fresher";
          return {
            jp_id,
            jp_title,
            jp_created_at,
            jp_type,
            jp_experience: exp
          };
        }
      );
    }

    return extractedData;
  }, [user, jobData]);
};
