import { useQuery } from "@tanstack/react-query";
import { getCandidate } from "app/api/candidate_api";

const useGetCandidate = (id, tab, ...rest) => {
  return useQuery({
    queryKey: ["candidate", id, tab, ...rest],
    queryFn: () => getCandidate(id),
    staleTime: Infinity,
    enabled: !!id
  });
};

export default useGetCandidate;
