import { State } from "country-state-city";
import { useMemo } from "react";
const useGetStates = (countries) => {
  return useMemo(() => {
    if (countries && countries.length > 0) {
      let states = [];
      countries.forEach((country) => {
        states.push(
          ...State.getStatesOfCountry(country.id).map(({ name, isoCode }) => ({
            id: isoCode,
            label: name,
            countryCode: country.id
          }))
        );
      });
      return states;
    }
    return State.getStatesOfCountry("IN").map(({ name, isoCode }) => ({
      id: isoCode,
      label: name
    }));
  }, [countries]);
};

export default useGetStates;
