import React from "react";
import { Typography, Box } from "@mui/material";
import JobDetailTitle from "./JobDetailTitle";
import JobDetailWrapper from "./JobDetailWrapper";
import { Money } from "@mui/icons-material";
function JobSalaryRange({ minSalaryRange, maxSalaryRange }) {
  return (
    <JobDetailWrapper>
      <Money />
      <Box>
        <JobDetailTitle>Yearly Salary Range</JobDetailTitle>
        <Box display={"flex"} gap={1}>
          <Typography color={"gray"}>
            Rs. {Number(minSalaryRange).toLocaleString("en-IN")}/year
          </Typography>
          <Typography>-</Typography>
          <Typography color={"gray"}>
            Rs. {Number(maxSalaryRange).toLocaleString("en-IN")}/year
          </Typography>
        </Box>
      </Box>
    </JobDetailWrapper>
  );
}

export default JobSalaryRange;
