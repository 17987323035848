import React from "react";
import JobDetailTitle from "./JobDetailTitle";
import { Box, Typography } from "@mui/material";
import JobDetailWrapper from "./JobDetailWrapper";
import { School } from "@mui/icons-material";
function JobQualification({ qualification }) {
  return (
    <JobDetailWrapper>
      <School />
      <Box>
        <JobDetailTitle>Qualification</JobDetailTitle>
        <Typography color={"gray"}>{qualification}</Typography>
      </Box>
    </JobDetailWrapper>
  );
}

export default JobQualification;
