import React from "react";
import { Typography } from "@mui/material";
import { TextBGColor } from "../JobList";
function GrayBGText({ loc }) {
  return (
    <Typography
      key={loc}
      sx={{
        color: "gray",
        bgcolor: TextBGColor,
        borderRadius: "2px",
        padding: "2px 4px"
      }}
    >
      {loc}
    </Typography>
  );
}

export default GrayBGText;
