import { Verified } from "@mui/icons-material";
import { Box } from "@mui/material";

function VerifiedText(props) {
  return (
    <Box display={"flex"} gap={1} color={"green"}>
      <Verified width={"5px"} height={"5px"} />
    </Box>
  );
}

export default VerifiedText;
