import React from "react";
import JobDetailTitle from "./JobDetailTitle";
import { Typography, Box } from "@mui/material";
import { WorkOutlined } from "@mui/icons-material";
import JobDetailWrapper from "./JobDetailWrapper";
function JobType({ type }) {
  return (
    <JobDetailWrapper>
      <WorkOutlined />
      <Box>
        <JobDetailTitle>Job Type</JobDetailTitle>
        <Typography color={"gray"}>{type}</Typography>
      </Box>
    </JobDetailWrapper>
  );
}

export default JobType;
