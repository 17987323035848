import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import JobDetailTitle from "./JobDetailTitle";
import JobDetailWrapper from "./JobDetailWrapper";
import { LocationOn } from "@mui/icons-material";
function JobLocation({ cities }) {
  return (
    <JobDetailWrapper>
      <LocationOn />
      <Box>
        <JobDetailTitle>Location</JobDetailTitle>
        {cities.length > 0 ? (
          <Grid container spacing={1}>
            {cities.map((city) => (
              <Grid key={city} item xs={cities.length > 1 ? 6 : 12}>
                <Typography key={city} variant="body2" color={"gray"}>
                  {city}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No location specified</Typography>
        )}
      </Box>
    </JobDetailWrapper>
  );
}

export default JobLocation;
