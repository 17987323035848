import { Box, Typography, Button, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import SalaryText from "../SalaryText";
import SquareImage from "../SquareImage";
import ExtraInfo from "./ExtraInfo";
import JobCities from "./JobCities";
import CircularDivider from "../CircularDivider";
function JobListItem({
  id,
  jobTitle,
  companyName,
  showExtraInfo,
  exp,
  jobType,
  locationType,
  companyId,
  cities,
  minSalary,
  maxSalary,
  handleViewClick,
  handleApplyClick
}) {
  return (
    <ListItem
      alignItems={"center"}
      sx={{
        borderBottom: "1px solid lightgray",
        ":hover": {
          bgcolor: "whitesmoke"
        }
      }}
      key={id}
    >
      <ListItemAvatar>
        <SquareImage companyName={companyName} companyId={companyId} />
      </ListItemAvatar>
      <Box width={"100%"}>
        <ListItemText
          primary={jobTitle}
          secondary={
            <Typography fontSize={".7rem"} color="gray">
              {companyName}
            </Typography>
          }
        />
        <Box
          display={"flex"}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
            width: "100%"
          }}
          gap={1}
          mt={1}
          overflow={"auto"}
        >
          {/* Cities */}
          <JobCities cities={cities} />

          <CircularDivider />

          {/* Salary */}
          <SalaryText minSalary={minSalary} maxSalary={maxSalary} />

          {/* Employment Type */}
          {showExtraInfo && <ExtraInfo exp={exp} jobType={jobType} locationType={locationType} />}
        </Box>
      </Box>
      <Box display={"flex"} sx={{ flexDirection: { xs: "column", md: "row" } }} gap={1}>
        <Button variant="outlined" color={"primary"} onClick={handleViewClick}>
          View
        </Button>
        <Button variant="contained" color={"primary"} onClick={handleApplyClick}>
          Apply
        </Button>
      </Box>
    </ListItem>
  );
}

export default JobListItem;
