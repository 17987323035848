import { useMutation } from "@tanstack/react-query";
import { updateCandidatePassword } from "app/api/candidate_api";

export const useUpdatePassword = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) =>
      updateCandidatePassword(params.id, params.newPass, params.oldPass, params.reset),
    onSuccess: (data, variables) => onSuccess(data),
    onError: onError
  });
};
