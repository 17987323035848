import { useMutation, useQuery } from "@tanstack/react-query";
import { getDeletedJobs, restoreJob } from "app/api/job_api";
import { DeletedJobHeaders } from "app/utils/global_constants";
import { useState } from "react";
import DeletedListWrapper from "./DeletedListWrapper";
import { Restore } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";

import TrashWrapper from "./shared/TrashWrapper";
function DeletedJobs(props) {
  const { user } = useAuth();
  const isAdmin = user.role === "SA";
  const [responseState, setResponseState] = useState({
    error: false,
    success: false,
    message: ""
  });
  const [columnFilters, setColumnFilters] = useState({
    sortOrder: "DESC",
    column: undefined,
    limit: 5,
    offset: 0,
    currentPage: 0
  });

  const {
    mutate: restore,
    isPending: isRestoring,
    isSuccess: isRestoredSuccess
  } = useMutation({
    mutationFn: (params) => restoreJob(params.id),
    onSuccess: () => {
      setResponseState((prev) => ({
        ...prev,
        success: true,
        message: "Job restored successfully"
      }));
    },
    onError: () =>
      setResponseState((prev) => ({ ...prev, error: true, message: "Job restore failed" }))
  });

  const { data: deletedJobs, isFetched } = useQuery({
    queryKey: [
      "deleted_jobs",
      columnFilters.limit,
      columnFilters.offset,
      columnFilters.currentPage,
      isRestoredSuccess
    ],
    queryFn: () =>
      getDeletedJobs(
        columnFilters.sortOrder,
        "jp_updated_at",
        columnFilters.limit,
        columnFilters.offset,
        !isAdmin ? user.id : undefined
      )
  });

  function handleChangeRowsPerPage(event) {
    setColumnFilters((prev) => ({ ...prev, limit: +event.target.value, currentPage: 0 }));
  }

  const handleChangePage = (_, newPage) => {
    setColumnFilters((prev) => ({ ...prev, currentPage: newPage, offset: newPage * prev.limit }));
  };

  function handleRestoreJob(selectedItem) {
    restore({ id: selectedItem.jp_id });
  }

  const content = isFetched ? (
    <DeletedListWrapper
      data={deletedJobs.rows.map(
        ({ jp_id, jp_title, jp_company_name, jp_created_at, jp_type }) => ({
          jp_id,
          jp_created_at,
          jp_title,
          jp_company_name,
          jp_type
        })
      )}
      count={deletedJobs.count}
      tableHeaders={DeletedJobHeaders}
      columnSort={["Title"]}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      columnFilters={columnFilters}
      actions={[{ icon: <Restore />, action: handleRestoreJob }]}
    />
  ) : (
    <span>Loading...</span>
  );

  const closeResponseSnackBar = () =>
    setResponseState({ error: false, success: false, message: "" });

  const showProgress = isRestoring ? true : false;
  return (
    <TrashWrapper
      showProgress={showProgress}
      onCloseSnackbar={closeResponseSnackBar}
      responseState={responseState}
    >
      {content}
    </TrashWrapper>
  );
}

export default DeletedJobs;
