import { CountryCodeMap } from "../candidate/shared/Constants";
import { formatExp as formatExp } from "app/utils/global_constants";
export const EmploymentTypes = [
  { id: 1, label: "Full-Time" },
  { id: 2, label: "Part-Time" },
  { id: 3, label: "Intern" },
  { id: 4, label: "Contract" },
  { id: 5, label: "Temporary" }
];

export const QualificationOptions = [
  { id: 1, label: "High School Diploma" },
  { id: 2, label: "Bachelor's Degree" },
  { id: 3, label: "Master's Degree" },
  { id: 4, label: "PhD" }
];

export const LanguageRequirementOptions = [
  { id: 1, label: "Hindi" },
  { id: 2, label: "English" }
];

export const PayOptions = [
  { value: "Paid", label: "Paid" },
  { value: "Unpaid", label: "Unpaid" }
];

export const ExperienceLevelOptions = [
  { id: 1, label: "Fresher/Entry-Level" },
  { id: 2, label: "Intermediate" },
  { id: 3, label: "Mid-Level" },
  { id: 4, label: "Senior" }
];

export const LocationOptions = [
  { id: 1, label: "Onsite" },
  { id: 2, label: "Remote/WFH" },
  { id: 3, label: "Hybrid" }
];

export const ExpFilterOptions = [
  { id: 1, value: 0, label: "Fresher (less than 1 year)" },
  { id: 2, value: 1, label: "1 year" },
  { id: 3, value: 2, label: "2 years" },
  { id: 4, value: 3, label: "3 years" },
  { id: 5, value: 4, label: "4 years" },
  { id: 6, value: 5, label: "5 years" },
  { id: 7, value: 6, label: "6 years" },
  { id: 8, value: 7, label: "7 years" },
  { id: 9, value: 8, label: "8 years" },
  { id: 10, value: 9, label: "9 years" },
  { id: 11, value: 10, label: "10 years" },
  { id: 12, value: 11, label: "11 years" },
  { id: 13, value: 12, label: "12 years" },
  { id: 14, value: 13, label: "13 years" },
  { id: 15, value: 14, label: "14 years" },
  { id: 16, value: 15, label: "15 years" },
  { id: 17, value: 16, label: "16 years" },
  { id: 18, value: 17, label: "17 years" },
  { id: 19, value: 18, label: "18 years" },
  { id: 20, value: 19, label: "19 years" },
  { id: 21, value: 20, label: "20 years" },
  { id: 22, value: 21, label: "21 years" },
  { id: 23, value: 22, label: "22 years" },
  { id: 24, value: 23, label: "23 years" },
  { id: 25, value: 24, label: "24 years" },
  { id: 26, value: 25, label: "25 years" },
  { id: 27, value: 26, label: "26 years" },
  { id: 28, value: 27, label: "27 years" },
  { id: 29, value: 28, label: "28 years" },
  { id: 30, value: 29, label: "29 years" },
  { id: 31, value: 30, label: "30 years" }
];

export const JobBenefits = [
  { id: 0, label: "None" },
  { id: 1, label: "Health Insurance" },
  { id: 2, label: "Retirement Plans" },
  { id: 3, label: "Paid Time Off" },
  { id: 4, label: "Flexible Work Hours" },
  { id: 5, label: "Professional Development" },
  { id: 6, label: "Parental Leave" },
  { id: 7, label: "Life and Disability Insurance" },
  { id: 8, label: "Employee Assistance Programs" },
  { id: 9, label: "Wellness Programs" },
  { id: 10, label: "Stock Options or Equity" },
  { id: 11, label: "Tuition Reimbursement" },
  { id: 12, label: "Employee Discounts" },
  { id: 13, label: "Transportation Benefits" },
  { id: 14, label: "Relocation Assistance" },
  { id: 15, label: "Employee Recognition Programs" },
  { id: 16, label: "Community Involvement" },
  { id: 17, label: "Legal Assistance" },
  { id: 18, label: "Flexible Spending Accounts (FSAs) or Health Savings Accounts (HSAs)" },
  { id: 19, label: "Employee Referral Programs" },
  { id: 20, label: "Maternity and Paternity Support" }
];

export const getSelectedCountries = (isEditPath, preloadedData, jp_country) => {
  const preSelectedCountries = isEditPath
    ? preloadedData?.jp_country
        .split("| ")
        .map((country) => ({ id: CountryCodeMap[country], label: country }))
    : [];

  if (jp_country) {
    const uniqueMap = new Map();
    preSelectedCountries.concat(jp_country).forEach((item) => {
      uniqueMap.set(item.id, item); // The key is the id
    });

    return Array.from(uniqueMap.values());
  }
  return preSelectedCountries;
};

export const getSelectedStates = (previousSelection, currentSelection) => {
  if (currentSelection) {
    const currentSelectionSetIds = new Set(currentSelection.map((item) => item.id));
    const selectedStates = previousSelection.filter((item) => currentSelectionSetIds.has(item.id));
    return selectedStates;
  }

  return previousSelection;
};
