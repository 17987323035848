import React from "react";
import { Box, Typography } from "@mui/material";
import { ReportGmailerrorredOutlined } from "@mui/icons-material";
function NoResultFound(props) {
  return (
    <Box
      height={"100%"}
      zIndex={2}
      position={"relative"}
      bgcolor={"#EEEEEE"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"10px"}
      sx={{ boxShadow: 4, p: "10px" }}
    >
      <Box textAlign={"center"} height={"100%"}>
        <ReportGmailerrorredOutlined sx={{ width: "120px", height: "120px" }} />
        <Typography variant="h6" color={"black"}>
          No Results Found
        </Typography>
        <Typography variant="subtitle2" color={"gray"}>
          Please use the filter options to modify your search parameters
        </Typography>
      </Box>
    </Box>
  );
}

export default NoResultFound;
