import { Box } from "@mui/material";
const CircularDivider = () => {
  return (
    <Box
      width={"4px"}
      height={"4px"}
      bgcolor={"black"}
      borderRadius={"100%"}
      sx={{ display: { xs: "none", md: "block" } }}
    >
      {" "}
    </Box>
  );
};

export default CircularDivider;
