import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Pebble, PebbleText } from "../JobDetailDialog";
function JobBenefitsRequired({
  jobBenefits,
  hiddenBenefits,
  allBenefits,
  showHiddenBenefits,
  setShowHiddenBenefits
}) {
  return (
    <Box mb={2}>
      <Typography gutterBottom variant="subtitle2" fontWeight={"bold"}>
        Benefits Provided
      </Typography>
      <Grid container display={"flex"} gap={2} alignItems={"center"}>
        {jobBenefits.length === 0 && "No benefits specified"}
        {jobBenefits.length > 0 &&
          jobBenefits.map((benefit) => (
            <Pebble
              key={benefit}
              sx={{
                textAlign: "center"
              }}
            >
              <PebbleText>{benefit}</PebbleText>
            </Pebble>
          ))}
        {showHiddenBenefits &&
          hiddenBenefits.map((benefit) => (
            <Pebble
              key={benefit}
              sx={{
                textAlign: "center"
              }}
            >
              <PebbleText>{benefit}</PebbleText>
            </Pebble>
          ))}
        {allBenefits.length > 4 && (
          <Pebble
            onClick={() => setShowHiddenBenefits(true)}
            sx={{
              backgroundColor: "#2b324c",
              color: "white",
              cursor: "pointer",
              ":hover": { backgroundColor: "#1a1e36" },
              transition: "background .5s, color .5s",
              display: `${showHiddenBenefits ? "none" : "block"}`
            }}
          >
            <PebbleText>{`+${allBenefits.length - 3} more`}</PebbleText>
          </Pebble>
        )}
      </Grid>
    </Box>
  );
}

export default JobBenefitsRequired;
