import { Avatar, Box, Grid } from "@mui/material";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { H1 } from "app/components/Typography";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import useUpdateCandidate from "app/hooks/candidate/useUpdateCandidate";
import useAuth from "app/hooks/useAuth";
import { convertToBase64, getInitialsOfName } from "app/utils/utils";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import CandidateTabs from "./CandidateTabs";

function CandidateProfile() {
  const { user, updateUser } = useAuth();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onUpdateSuccess = (res) => {
    setSuccess(true);
  };

  const onUpdateError = (error) => {
    setError(true);
    setSuccess(false);
  };

  const {
    data: updateResult,
    mutate: updateCandidate,
    isPending: isUpdating
  } = useUpdateCandidate(user.id, onUpdateSuccess, onUpdateError, (data) => {
    const updatedUser = data.candidate;
    updateUser({
      role: "candidate",
      name: updatedUser.jp_candidate_name,
      email: updatedUser.jp_email,
      id: updatedUser.jp_id
    });
  });

  const { data: candidate, isFetched: isCandidateFetched } = useGetCandidate(
    user.id,
    1,
    isUpdating,
    updateResult?.candidate?.jp_updated_at
  );

  const profile = candidate && candidate.jp_photo ? candidate.jp_photo.data : "";

  return (
    <Fragment>
      {success && (
        <ResponseSnackbar
          message={"Updated profile successfully"}
          open={success}
          onClose={() => setSuccess(false)}
          variant={"filled"}
          severity={"success"}
          hideDuration={4000}
        />
      )}
      {error && (
        <ResponseSnackbar
          message={"Update profile failed! Please try again later."}
          open={error}
          onClose={() => setError(false)}
          variant={"filled"}
          severity={"error"}
          hideDuration={4000}
        />
      )}
      <Box
        sx={{
          backgroundColor: "#EEEEEE",
          backgroundImage: " radial-gradient(circle, #4f46e4 10%, transparent 11%)",
          backgroundSize: "1em 1em",
          opacity: 1,
          display: { xs: "hidden", md: "block" }
        }}
        color={"lightblue"}
        minHeight={"200px"}
        maxHeight={"300px"}
        width={"100%"}
      ></Box>
      <Box paddingLeft={"50px"}>
        <Avatar
          sx={{
            width: "150px",
            height: "150px",
            bgcolor: "#222a45",
            fontSize: "5.2rem",
            zIndex: "4",
            marginTop: "-100px",
            border: "8px solid white"
          }}
          src={`data:image/jpeg;base64,${convertToBase64(profile)}`}
          children={getInitialsOfName(user.name)}
        />
        {isCandidateFetched && (
          <Grid container gap={4} mt={1}>
            <Grid item justifyContent={"center"} md={9}>
              <H1
                sx={{
                  wordWrap: "break-word",
                  background: "white",
                  display: "inline",
                  p: 1
                }}
              >
                {candidate?.jp_candidate_name || ""}
              </H1>
              <CandidateTabs candidate={candidate} updateCandidate={updateCandidate} />
            </Grid>
          </Grid>
        )}
      </Box>
    </Fragment>
  );
}

export default CandidateProfile;
