import axios from "axios";

export async function getApplicants(
  sort,
  column,
  limit,
  offset,
  searchQuery,
  post,
  status,
  id,
  isShared
) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery || "",
    orderBy: column,
    orderByValue: sort,
    id: id,
    post: post,
    status: status,
    isShared: isShared
  };
  const response = await axios.get("/apply", { params: queryParams });
  return { count: response.data.count, data: response.data.rows };
}

export async function getApplicationOfCompany({ id, status, limit, offset, searchQuery }) {
  try {
    const queryParams = { id: id, status: status, limit, offset, searchQuery };
    const response = await axios.get("/apply/company", { params: queryParams });
    return { data: response.data.data, count: response.data.count };
  } catch (e) {
    console.log(e);
  }
}

export async function postApplication(application) {
  const response = await axios.post("/apply", application);
  return response;
}

export async function updateStatus(id, status) {
  const response = await axios.patch("/apply/status/" + id, status);
  return response;
}

export async function getCandidateApplications(candidateId, jobPostId, searchQuery, status) {
  const response = await axios.get("/apply/candidate", {
    params: { id: candidateId, searchQuery: searchQuery, status, jobId: jobPostId }
  });
  return Object.values(response.data).map(
    ({
      jp_id,
      jp_candidate_id,
      jp_status,
      jp_company_id,
      jp_jobpost_id,
      jp_is_share_to_company,
      jp_created_at,
      company,
      candidate,
      jobposting
    }) => ({
      jp_id,
      jp_candidate_id,
      jp_created_at,
      jp_jobpost_id,
      jp_company_id,
      jp_status,
      jp_is_share_to_company,
      ...company,
      ...candidate,
      ...jobposting
    })
  );
}

export async function getApplicationByStatus(status) {
  const response = await axios.get("/apply/status", { params: { status: status } });
  return response.data;
}

export async function updateApplication(application, id) {
  const response = await axios.put("/apply/" + id, application);
  return response.data;
}

export async function getApplicationCount(status) {
  const response = await axios.get("/apply/count", { params: { status } });
  return response.data;
}
