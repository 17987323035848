import React from "react";
import CircularDivider from "../CircularDivider";
import { Typography } from "@mui/material";
import GrayBGText from "./GrayBGText";
import { TextBGColor } from "../JobList";
function ExtraInfo({ jobType, exp, locationType }) {
  return (
    <>
      <CircularDivider />
      <Typography
        sx={{
          color: "gray",
          bgcolor: TextBGColor,
          borderRadius: "2px",
          padding: "2px 4px",
          display: { xs: "none", md: "block" }
        }}
      >
        {jobType}
      </Typography>
      <CircularDivider sx={{ display: { xs: "none", md: "block" } }} />
      {/* Experience */}
      <Typography sx={{ display: { xs: "none", md: "block" } }}>{exp}</Typography>
      <CircularDivider />
      {/* Location Type */}
      {locationType && <GrayBGText loc={locationType} />}
    </>
  );
}

export default ExtraInfo;
