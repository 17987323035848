import { useMemo } from "react";
import { City } from "country-state-city";
const useGetCities = (selectedStates) => {
  return useMemo(() => {
    let cities = [];

    if (selectedStates && selectedStates.length > 0) {
      selectedStates.forEach((state) => {
        const citiesOfState = City.getCitiesOfState(state.countryCode, state.id).map(
          ({ name, latitude, stateCode, countryCode }) => ({
            id: `${stateCode}-${countryCode}-${name}`,
            label: `${stateCode}-${name}`
          })
        );

        cities.push(...citiesOfState);
      });

      return cities;
    }

    return City.getCitiesOfCountry("IN").map(({ name, latitude }) => ({
      id: latitude,
      label: name
    }));
  }, [selectedStates]);
};

export default useGetCities;
