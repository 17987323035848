import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Paragraph } from "app/components/Typography";
function DetailAccordian({ title, details }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
        color="gray"
        style={{
          backgroundColor: "#f5f5f5",
          color: "black",
          borderRadius: "5px"
        }}
        aria-controls="Job requirements"
      >
        <Paragraph fontWeight={"bold"} letterSpacing={0.8}>
          {title}
        </Paragraph>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          boxShadow: 3,
          border: "1px solid lightgray",
          borderRadius: "5px",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        {details.map((item) => item)}
      </AccordionDetails>
    </Accordion>
  );
}

export default DetailAccordian;
