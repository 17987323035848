import { Box } from "@mui/material";
import AutoCompleteMulti from "app/views/material-kit/auto-complete/AutoCompleteMulti";
import { EmploymentTypes } from "../../Constants";
import { useState } from "react";
function FilterByType({ onChecked }) {
  const [type, setType] = useState([]);
  return (
    <Box>
      <AutoCompleteMulti
        values={EmploymentTypes}
        selectedValue={type}
        label={"Filter by employment type"}
        onSelection={(newValue) => {
          setType(newValue);
          onChecked(newValue);
        }}
      />
    </Box>
  );
}

export default FilterByType;
