import React from "react";
import { Typography, Box } from "@mui/material";
import JobDetailTitle from "./JobDetailTitle";
import JobDetailWrapper from "./JobDetailWrapper";
import { WorkHistory } from "@mui/icons-material";
function JobExp({ exp }) {
  return (
    <JobDetailWrapper>
      <WorkHistory />
      <Box>
        <JobDetailTitle>Experience</JobDetailTitle>
        <Typography color={"gray"}>{exp}</Typography>
      </Box>
    </JobDetailWrapper>
  );
}

export default JobExp;
