import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
function FilterButton({ onClick }) {
  return (
    <Box
      mb={2}
      sx={{
        maxWidth: "100px",
        borderRadius: "20px",
        background: "#2b324c",
        zIndex: 2,
        position: "relative",
        ml: "10px",
        display: { xs: "flex", md: "none" },
        alignItems: "center"
      }}
      onClick={onClick}
    >
      <IconButton color="white">
        <FilterAlt sx={{ color: "white" }} />
      </IconButton>
      <Typography variant="subtitle2" color={"white"}>
        Filter
      </Typography>
    </Box>
  );
}

export default FilterButton;
