import { useState, Fragment } from "react";
import { MenuItem, Select } from "@mui/material";
import { useUpdateApplication } from "app/hooks/application/useUpdateApplication";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import useNotification from "app/hooks/useNotification";
import { useQueryClient } from "@tanstack/react-query";
function ShareStatusDropdown({
  status,
  applicationId,
  companyId,
  companyName,
  companyEmail,
  jobTitle,
  candidateName
}) {
  const queryClient = useQueryClient();
  const [value, setValue] = useState(status);
  const [response, setResponse] = useState({
    success: false,
    error: false,
    message: ""
  });

  const { createNotification } = useNotification();
  const notification = {
    jp_company_id: companyId,
    jp_title: "New Application",
    jp_message: `Candidate: ${candidateName} has applied for the role of ${jobTitle}`,
    jp_status: "pending",
    mail: {
      jp_email: companyEmail,
      jp_company_name: companyName,
      jp_candidate_name: candidateName
    }
  };
  const { mutate: updateApplication } = useUpdateApplication({
    onSuccess: (data) => {
      if (value === 1) {
        createNotification(notification, "company");
        setResponse({ error: false, success: true, message: "Application Shared Successfully" });
      } else {
        setResponse({ error: false, success: true, message: "Application Hidden Successfully" });
      }
      queryClient.invalidateQueries({ queryKey: ["candidates"] });
    },
    onError: () => setResponse({ error: true, success: false })
  });

  function handleOnChange() {
    if (value === 1) {
      setValue(0);
      updateApplication({ application: { jp_is_share_to_company: 0 }, id: applicationId });
    } else {
      setValue(1);
      updateApplication({ application: { jp_is_share_to_company: 1 }, id: applicationId });
    }
  }
  return (
    <Fragment>
      {response.success && (
        <ResponseSnackbar
          open={true}
          message={response.message}
          severity={"success"}
          onClose={() => setResponse((prev) => ({ ...prev, success: false }))}
        />
      )}
      {response.error && (
        <ResponseSnackbar
          open={true}
          message={"Failed sharing candidate"}
          severity={"error"}
          onClose={() => setResponse((prev) => ({ ...prev, error: false }))}
        />
      )}
      <Select value={value} sx={{ minWidth: 100 }} onChange={handleOnChange}>
        <MenuItem value={1}>Shared</MenuItem>
        <MenuItem value={0}>Hidden</MenuItem>
      </Select>
    </Fragment>
  );
}

export default ShareStatusDropdown;
