import React from "react";
import { AppBar, Dialog, IconButton, Toolbar, Typography, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
function FilterDialog({ open, onClose, children }) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} sx={{ display: { xs: "block", md: "none" } }}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: "white" }} variant="h6" component={"div"}>
            Filter Jobs
          </Typography>
        </Toolbar>
      </AppBar>
      <Box>{children}</Box>
    </Dialog>
  );
}

export default FilterDialog;
