import { Autocomplete, Box, TextField } from "@mui/material";
import { ExpFilterOptions } from "../../Constants";

function FilterByExp({ value, onChecked }) {
  return (
    <Box>
      <Autocomplete
        onChange={(event, newValue) => {
          onChecked(newValue);
        }}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        sx={{ flex: 1, minWidth: { md: "300px" } }}
        size="small"
        value={value}
        options={ExpFilterOptions}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select Experience Level" />
        )}
      />
    </Box>
  );
}

export default FilterByExp;
