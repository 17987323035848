import { Box, TextField } from "@mui/material";
import { getAllSkills } from "app/api/skill_api";
import AutoCompleteMulti from "app/views/material-kit/auto-complete/AutoCompleteMulti";
import { useEffect, useState } from "react";
function CandidateFilter({ selectedSkill, experience, setSkill, setExperience }) {
  const [skillOptions, setSkillOptions] = useState([]);

  useEffect(() => {
    const loadSkills = async () => {
      const skillOptions = await getAllSkills();
      if (skillOptions) {
        setSkillOptions(skillOptions.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill })));
      }
    };

    loadSkills();
  }, [selectedSkill]);

  return (
    <Box display={"flex"} gap={3} my={2}>
      <AutoCompleteMulti // Filter by Skill
        onSelection={(value) => {
          setSkill(value);
        }}
        label={"Filter by Skills"}
        values={skillOptions}
        selectedValue={selectedSkill}
      />
      <TextField
        label="Min Years of Experience"
        type="number"
        value={experience[0]}
        size="small"
        sx={{ minWidth: "200px" }}
        onChange={(event) => setExperience((prev) => [event.target.value, prev[1]])}
        InputProps={{
          inputProps: { min: 0, max: 100 }
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        label="Max Years of Experience"
        type="number"
        value={experience[1]}
        size="small"
        sx={{ minWidth: "200px" }}
        onChange={(event) => setExperience((prev) => [prev[0], event.target.value])}
        InputProps={{
          inputProps: { min: 0, max: 100 }
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  );
}

export default CandidateFilter;
