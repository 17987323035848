import { getJobListings } from "app/api/job_api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../useAuth";
const useGetJobs = ({
  sortOrder,
  column,
  limit,
  offset,
  searchTerm,
  id,
  type,
  exp,
  salary,
  skill,
  location,
  city,
  enabled,
  staleTime = 30000,
  ...rest
}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: [
      "job_listings",
      sortOrder,
      column,
      limit,
      offset,
      searchTerm,
      type,
      exp,
      skill,
      salary,
      city,
      rest,
      location
    ],
    queryFn: () =>
      getJobListings(
        sortOrder,
        column,
        limit,
        offset,
        searchTerm,
        id,
        type,
        exp,
        skill,
        location,
        city,
        salary
      ),
    enabled: enabled
  });
};

export default useGetJobs;
