import React from "react";
import { Box, Typography } from "@mui/material";
import { School, Face } from "@mui/icons-material";
import { EmailSharp, LocationCity, Phone } from "@mui/icons-material";
const Subheader = ({ header, icon }) => {
  return (
    <Box display={"flex"} gap={2} alignContent={"center"} mb={1}>
      <Box width={"10px"} height={"10px"}>
        {icon}
      </Box>
      <Typography variant="subtitle2" color={"black"}>
        {header}
      </Typography>
    </Box>
  );
};

const IconStyles = { width: "20px", height: "20px" };

function AboutCandidate({ about, education, address, contact, email }) {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <section>
        <Subheader header={"Bio"} icon={<Face sx={IconStyles} />} />
        <Typography>{about}</Typography>
      </section>
      <section>
        <Subheader header={"Education"} icon={<School sx={IconStyles} />} />
        <Typography>{education || ""}</Typography>
      </section>
      <section>
        <Subheader header={"Address"} icon={<LocationCity sx={IconStyles} />} />
        <Typography>{address || ""}</Typography>
      </section>
      <section>
        <Subheader header={"Contact"} icon={<Phone sx={IconStyles} />} />
        <Typography>{contact || ""}</Typography>
      </section>
      <section>
        <Subheader header={"Email"} icon={<EmailSharp sx={IconStyles} />} />
        <Typography>{email || ""}</Typography>
      </section>
    </Box>
  );
}

export default AboutCandidate;
