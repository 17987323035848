import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { Place } from "@mui/icons-material";
import DOMPurify from "dompurify";
import ReadMoreHTML from "app/components/ReadMoreHTML";
function JobDescription({ cities, states, countries, description }) {
  const sanitizedDescription = DOMPurify.sanitize(description);
  return (
    <>
      <Typography variant={"subtitle1"} margin={0} fontWeight={500}>
        Job Description
      </Typography>
      <Box display={"flex"} alignItems={"center"} gap={1} color={"gray"} fontSize={".8rem"}>
        <Place />
        <span>{cities}</span>
      </Box>
      <ReadMoreHTML
        readMoreText="Learn more"
        readLessText="Hide details"
        text={sanitizedDescription}
        maxChars={200}
      />
    </>
  );
}

export default JobDescription;
