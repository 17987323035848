import { TextField, InputAdornment, Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
function PasswordTextField({
  onBlur,
  name,
  value,
  label = "Password",
  toggleView,
  onChange,
  errors,
  helperText
}) {
  const [view, setView] = useState(false);
  const toggleVisibility = () => {
    setView((prev) => !prev);
  };
  return (
    <TextField
      fullWidth
      size="small"
      name={name}
      type={view ? "text" : "password"}
      label={label}
      variant="outlined"
      onBlur={onBlur}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginLeft: "-1px", marginRight: "-12px" }}>
            <Button size={"medium"} onClick={toggleVisibility}>
              {view ? <VisibilityOff /> : <Visibility />}
            </Button>
          </InputAdornment>
        )
      }}
      InputLabelProps={{ shrink: true }}
      onChange={onChange}
      helperText={helperText}
      error={errors}
      sx={{ mb: 1.5 }}
    />
  );
}

export default PasswordTextField;
