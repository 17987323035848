import { useMutation } from "@tanstack/react-query";
import { updateCandidate } from "app/api/candidate_api";
const useUpdateCandidate = (id, onSuccess, onError, onSettled) => {
  return useMutation({
    mutationFn: (params) => updateCandidate(params.data, id),
    onSuccess: (res, variables, ctx) => {
      onSuccess(res);
    },
    onError: (error) => onError(error),
    onSettled: (data) => onSettled(data)
  });
};
export default useUpdateCandidate;
