import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, styled, TextField, Typography } from "@mui/material";
import { H1 } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import dreamer from "../../../assets/illustrations/dreamer.svg";
import ChangeLoginLink from "./shared/ChangeLoginLink";
import PasswordTextField from "./shared/PasswordTextField";

// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex"
}));

const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)"
}));

const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#1A2038",
  minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

// initial login credentials
const initialValues = {
  email: "",
  password: "",
  remember: false
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be 8 character length")
    .required("Password is required!"),
  email: Yup.string().email("Invalid Email address").required("Email is required!")
});

export default function JwtLoginCompany() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    userNotFound: {
      status: false,
      message: "Could not find user with this email address"
    },
    passwordIncorrect: {
      status: false,
      message: "Incorrect password. Please enter correct password"
    },
    suspendedAccount: {
      status: false,
      message: "Login restricted. Account suspended."
    }
  });

  const { login } = useAuth();

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const type = "company";
      const res = await login(values.email, values.password, type);
      if (!res.success) {
        if (res.message === "Could not find user") {
          setError((prev) => ({ ...prev, userNotFound: { status: true } }));
        }
        if (res.message === "Incorrect password. Please enter correct password") {
          setError((prev) => ({ ...prev, passwordIncorrect: { status: true } }));
        }
        if (res.message === "Account suspended. Cannot login.") {
          setError((prev) => ({ ...prev, suspendedAccount: { status: true } }));
        }
        return;
      }

      navigate("/dashboard/default");
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const registerLink = "/session/signup/company";

  return (
    <StyledRoot>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12} sx={{ display: { xs: "none", md: "block" } }}>
            <div className="img-wrapper">
              <Box component={"img"} src={dreamer} width="100%" alt="" />
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <H1 mb={4}>Company LogIn</H1>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: !error.userNotFound.status && 3 }}
                    />

                    {error.userNotFound.status && (
                      <Typography sx={{ marginBottom: 3, color: "red" }}>
                        No user with this email exists
                      </Typography>
                    )}

                    <PasswordTextField
                      name={"password"}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      errors={Boolean(errors.password && touched.password)}
                      onBlur={handleBlur}
                    />

                    {error.passwordIncorrect.status && (
                      <Typography sx={{ marginBottom: 3, color: "red" }}>
                        The password that you've entered is incorrect.
                      </Typography>
                    )}

                    {error.suspendedAccount.status && (
                      <Typography sx={{ marginBottom: 2, color: "red" }}>
                        Login restricted. Your account is suspended.
                      </Typography>
                    )}

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2, width: "100%" }}
                    >
                      Login
                    </LoadingButton>

                    <FlexBox justifyContent="space-between" mb={2}>
                      <NavLink
                        to="/session/forgot-password"
                        style={{ color: "#1976D2" }}
                        state={{ role: "company" }}
                      >
                        Forgot password?
                      </NavLink>
                      {!location.pathname.includes("admin") && (
                        <NavLink to={registerLink} style={{ color: "#1976D2", marginLeft: 5 }}>
                          Company Register
                        </NavLink>
                      )}
                    </FlexBox>
                    <ChangeLoginLink />
                  </form>
                )}
              </Formik>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </StyledRoot>
  );
}
