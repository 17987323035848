import { Box, Button } from "@mui/material";
function UploadFileTitle({ title, actionTitle, shouldPreview, previewClick }) {
  return (
    <Box display={"flex"} gap={2} alignItems={"center"} justifyContent={"start"}>
      <h3>{title}</h3>
      {shouldPreview && (
        <Button variant="contained" size="small" sx={{ padding: 0 }} onClick={previewClick}>
          {actionTitle}
        </Button>
      )}
    </Box>
  );
}

export default UploadFileTitle;
