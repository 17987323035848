import { useQuery } from "@tanstack/react-query";
import { getCompany } from "app/api/company_api";

export const useGetCompany = ({ id, isUpdating }) => {
  return useQuery({
    queryKey: ["company", id, isUpdating],
    queryFn: () => getCompany(id),
    staleTime: Infinity,
    enabled: !!id
  });
};
