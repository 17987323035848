import React from "react";
import { Box, Avatar } from "@mui/material";
import ReadMoreText from "app/components/ReadMoreText";
import { H2 } from "app/components/Typography";
import { convertToBase64 } from "app/utils/utils";
import { Verified } from "@mui/icons-material";
function CompanyDescription({ company, job }) {
  return (
    <Box
      display={"flex"}
      gap={2}
      sx={{ border: "1px solid lightgray", borderRadius: "10px", p: 1 }}
    >
      <Box>
        <Avatar
          sx={{ width: 56, height: 56 }}
          src={`data:image/jpeg;base64,${convertToBase64(
            company && company.jp_logo ? company.jp_logo.data : ""
          )}`}
        />
      </Box>
      <Box>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <H2>{job.jp_company_name}</H2>
          <Verified sx={{ color: "green" }} />
        </Box>
        <ReadMoreText text={company ? company.jp_about_company : ""} maxChars={200} />
      </Box>
    </Box>
  );
}

export default CompanyDescription;
