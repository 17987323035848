import { convertISODateTimeToDate } from "app/utils/utils";
import axios from "axios";
export async function postJob(jobDetails) {
  return axios
    .post("/jobs", jobDetails, { headers: { Authorization: localStorage.getItem("token") } })
    .then(function (response) {
      return response;
    })
    .catch(function (err) {
      throw Error(err);
    });
}

export async function deleteJob(id) {
  return axios.patch("/jobs/" + id, { jp_is_deleted: true }).catch((err) => {
    throw Error(err);
  });
}

export async function getJobCount() {
  const response = await axios.get("/jobs/count");
  return response.data;
}

export async function restoreJob(id) {
  return axios.patch("/jobs/" + id, { jp_is_deleted: false }).catch((err) => {
    throw Error(err);
  });
}

export async function updateJob(jobDetails, id) {
  const res = await axios.put("/jobs/" + id, jobDetails);
  return res.data;
}

export async function getJobListings(
  sort,
  column,
  limit,
  offset,
  searchQuery,
  id,
  type,
  exp,
  skill,
  location,
  city,
  salary
) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery,
    orderBy: column,
    orderByValue: sort,
    id: id,
    type,
    exp,
    skill,
    location,
    salary,
    city
  };
  const response = await axios.get("/jobs", {
    params: queryParams
  });
  let data = [];
  if (response.status !== 204) {
    data = Object.values(response.data.rows).map((value) => ({
      ...value,
      jp_created_at: convertISODateTimeToDate(value.jp_created_at),
      jp_company_name: value.company.jp_company_name
    }));
    return { count: response.data.count, data: data };
  }
  return { count: 0, data: data };
}

export async function getJobListingByCompany(
  searchQuery,
  sort,
  column,
  limit,
  offset,
  id,
  type,
  exp,
  city,
  location
) {
  const queryParams = {
    searchQuery,
    limit: limit,
    offset: offset,
    orderBy: column,
    orderByValue: sort,
    type: type,
    exp: exp,
    location: location,
    city: city,
    id: id
  };
  const response = await axios.get("/jobs/company", {
    params: queryParams
  });
  let data = [];

  if (response.status !== 204) {
    data = Object.values(response.data.rows).map((value) => {
      delete value["jp_started_at"];
      return {
        ...value,
        jp_start_date: convertISODateTimeToDate(value.jp_start_date),
        jp_end_date: convertISODateTimeToDate(value.jp_end_date),
        jp_created_at: convertISODateTimeToDate(value.jp_created_at)
      };
    });
    return { count: response.data.count, data: data };
  }

  return { count: 0, data: data };
}

export async function getDeletedJobs(sort, column, limit, offset, companyId, searchQuery, id) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery,
    orderBy: column,
    orderByValue: sort,
    id: id,
    companyId: companyId
  };
  const response = await axios.get("/jobs/deleted", { params: queryParams });
  const data = response.data.rows.map((value) => ({
    ...value,
    jp_created_at: convertISODateTimeToDate(value.jp_created_at)
  }));
  return { rows: data, count: response.data.count };
}

export async function getSingleJobListing(id) {
  const queryParams = {
    id: id
  };
  const response = await axios.get("/jobs", { params: queryParams });
  return response.data;
}

export async function getCities(city) {
  const res = await axios.get("https://countriesnow.space/api/v0.1/countries/population/cities", {
    params: { city }
  });

  return res.data;
}
