import { useMemo } from "react";

const useCheckJobsData = (isAllJobs, allJobs) => {
  return useMemo(() => {
    let tableData = [];
    let count = 0;

    if (isAllJobs) {
      count = allJobs.count;
      tableData = allJobs.data;
    } else {
      count = 0;
      tableData = [];
    }

    return { tableData, count };
  }, [allJobs]);
};

export default useCheckJobsData;
