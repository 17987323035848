import React from "react";
import { Box, Slider, Typography } from "@mui/material";
function SalaryRange({ value, handleChange, valuetext }) {
  return (
    <Box px={2}>
      <Typography variant="subtitle2">Salary Range</Typography>
      <Slider
        getAriaLabel={() => "Salary range"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        marks={[
          { value: 0, label: "0 lakhs" },
          { value: 100, label: "100 lakhs" }
        ]}
      />
    </Box>
  );
}

export default SalaryRange;
