import axios from "axios";

export const generateOTP = async ({ email, type, name }) => {
  const res = await axios.post("/send-otp", { email, type, name });
  return res.data;
};

export const verifyOTP = async ({ email, otp }) => {
  const res = await axios.post("/verify-otp", { email, otp });
  return res.data;
};
