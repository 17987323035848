import { EmailSharp, FileDownload, Visibility } from "@mui/icons-material";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import { MatxLoading } from "app/components";
import CustomDialog from "app/components/CustomDialog";
import ListWrapper from "app/components/ListWrapper";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import SendMailForm from "app/components/SendMailForm";
import { useGetApplicants } from "app/hooks/application/useGetApplicants";
import { useGetApplicantsForCompany } from "app/hooks/application/useGetApplicantsForCompany";
import { useDownloadResume } from "app/hooks/candidate/useDownloadResume";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import useGetJob from "app/hooks/job/useGetJob";
import useAuth from "app/hooks/useAuth";
import { StatusOptions } from "app/utils/global_constants";
import { forwardRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import JobDetailDialog from "../jobs/shared/JobDetailDialog";
import FullScreenDialog from "../material-kit/dialog/FullScreenDialog";
import { useAllApplicantExtract } from "./hook/useAllApplicantExtract";
import { useCompanyApplicantExtract } from "./hook/useCompanyApplicantExtract";
import AppliedCandidateTable from "./shared/AppliedCandidateTable";
import CandidateDetail from "./shared/CandidateDetail";
import CompanyCandidateTable from "./shared/CompanyCandidateTable";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SATableHeader = [
  "Applied On",
  "Company",
  "Candidate",
  "Job Title",
  "Current CTC",
  "Status",
  "Download Resume",
  "View Candidate",
  "View Job",
  "Mail Candidate"
];

const CompanyTableHeader = [
  "Applied On",
  "Candidate",
  "Job Title",
  "Current CTC",
  "Notice Period",
  "Status",
  "Download Resume",
  "View Candidate"
];

function AppliedCandidate(props) {
  const { user } = useAuth();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const statusByParams = searchParams.get("status");

  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(
    state ? [StatusOptions.find((option) => option.value === state.status)] : []
  );

  // Control state of dialogs
  const [mailOpen, setMailOpen] = useState(false); // Controls opening and closing of mail dialog
  const [candidateMail, setCandidateMail] = useState();
  const [mailStatus, setMailStatus] = useState(false); // For snackbar
  const [candidateDialog, setCandidateDialog] = useState(false);
  const [jobDialog, setJobDialog] = useState(false);

  // For pagination of data table
  const [columnFilters, setColumnFilters] = useState({
    sortOrder: "DESC",
    column: "jp_created_at",
    limit: 10,
    offset: 0,
    page: 0,
    post: "",
    status: state ? state.status : statusByParams || ""
  });

  const [resumeId, setResumeId] = useState();
  const [jobId, setJobId] = useState(null);
  const [candidateId, setCandidateId] = useState();
  const [filename, setFilename] = useState();

  // Get Job for Job Detail Dialgo
  const { data: selectedJob } = useGetJob(jobId);

  // This get all applicants whether they are shared to company or not (used for HR role)
  const { data: appliedCandidates, isFetching: isApplicantsFetching } = useGetApplicants({
    sort: columnFilters.sortOrder,
    column: columnFilters.column,
    limit: columnFilters.limit,
    offset: columnFilters.offset,
    searchQuery: searchTerm,
    post: columnFilters.post,
    status: columnFilters.status,
    isShared: user.role === "company" ? 1 : null
  });

  // This gets all applicants for a specific company
  const { data: applicantsToCompany, isFetching: isCompanyAppsFetching } =
    useGetApplicantsForCompany({
      id: user.id,
      status: status.map((obj) => obj.value).join(","),
      limit: columnFilters.limit,
      offset: columnFilters.offset,
      searchQuery: searchTerm
    });

  // Download resume
  const { refetch: getResume, isFetching: isFetchingResume } = useDownloadResume({
    id: resumeId,
    fileName: filename
  });

  // Get individual candidate's data
  const { data: selectedApplicant } = useGetCandidate(candidateId);

  const companyApplicants = useCompanyApplicantExtract(
    applicantsToCompany ? applicantsToCompany : { data: [], count: 0 },
    user.id,
    applicantsToCompany
  );

  // Extracts necessary columns from applicants
  const allApplicants = useAllApplicantExtract(
    appliedCandidates ? appliedCandidates : { data: [], count: 0 }
  );

  function downloadResume(selectedRow) {
    let application;
    if (user.role === "SA") {
      application = appliedCandidates.data.find(
        (application) => application.jp_id === selectedRow.jp_id
      );
    }

    if (user.role === "company") {
      application = applicantsToCompany.data.find(
        (candidate) => candidate.jp_id === selectedRow.jp_id
      );
    }

    setResumeId(application.jp_candidate_id);
    setFilename(`${application.Candidate.jp_candidate_name}_resume`);

    if (resumeId) {
      getResume();
    }
  }

  const changeSortOrder = () => {
    setColumnFilters((prevValue) => ({
      ...prevValue,
      sortOrder: prevValue.sortOrder === "ASC" ? "DESC" : "ASC"
    }));
  };

  const handleChangePage = (_, newPage) => {
    setColumnFilters((prevPage) => ({
      ...prevPage,
      page: newPage,
      offset: newPage * prevPage.limit
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setColumnFilters((prevState) => ({ ...prevState, limit: +event.target.value, page: 0 }));
  };

  const handleColumnFilterChange = (value, type, checked) => {
    if (type === "status") {
      if (checked) {
        setColumnFilters((prevValue) => ({
          ...prevValue,
          status: prevValue.status.concat(",").concat(value)
        }));
      } else {
        const newStatus = columnFilters.status.split(",").filter((status) => status !== value); // removing the status that is unchecked
        setColumnFilters((prevValue) => ({ ...prevValue, status: newStatus.join(",") })); // converting the list back to comma separated string
      }
    }

    if (type === "post") {
      if (checked) {
        setColumnFilters((prevValue) => ({
          ...prevValue,
          post: prevValue.post.concat(",").concat(value)
        }));
      } else {
        const newPost = columnFilters.post.split(",").filter((post) => post !== value); // removing the post that is unchecked
        setColumnFilters((prevValue) => ({ ...prevValue, post: newPost.join(",") }));
      }
    }
  };

  const openCandidateDetailDialog = (selectedRow, allCandidates) => {
    if (allCandidates) {
      const candidate = allCandidates.data.find(
        (candidate) => candidate.jp_id === selectedRow.jp_id
      );
      setCandidateId(candidate.jp_candidate_id);
      setCandidateDialog(true);
    }
  };

  const openJobDetailDialog = (selectedRow) => {
    setJobId(selectedRow.jp_jobpost_id);
    setJobDialog(true);
  };

  const TableActions = () => {
    const actions = [
      {
        icon: (
          <Tooltip title="Download Resume">
            <FileDownload style={{ color: "#01579b" }} />
          </Tooltip>
        ),
        action: downloadResume
      },
      {
        icon: (
          <Tooltip title="View Candidate Details">
            <Visibility style={{ color: "#01579b" }} />
          </Tooltip>
        ),
        action: (rowId) =>
          openCandidateDetailDialog(
            rowId,
            user.role === "SA" ? appliedCandidates : applicantsToCompany
          )
      },
      {
        icon: (
          <Tooltip title="View Job Details">
            <Visibility style={{ color: "#01579b" }} />
          </Tooltip>
        ),
        action: (rowId) => openJobDetailDialog(rowId)
      },
      {
        icon: (
          <Tooltip title="Send email to candidate">
            <EmailSharp style={{ color: "#01579b" }} />
          </Tooltip>
        ),
        action: (rowId) => {
          setMailOpen(true);
          const selectedApplication = appliedCandidates.data.find(
            (application) => application.jp_id === rowId.jp_id
          );
          setCandidateMail(selectedApplication.Candidate.jp_email);
        }
      }
    ];

    if (user.role === "SA") {
      return actions;
    }

    if (user.role === "company") {
      actions.pop();
      actions.pop();
      return actions;
    }

    return [];
  };

  return (
    <ListWrapper
      editOpen={false}
      alertOpen={false}
      searchlabel={"Search By Job Title / Candidate Name"}
      onChangeSearch={(searchValue) => {
        setSearchTerm(searchValue);
      }}
    >
      {isCompanyAppsFetching || (isApplicantsFetching && <MatxLoading />)}
      {isFetchingResume && <MatxLoading />}
      {selectedApplicant && (
        <CustomDialog
          fullWidth={true}
          open={candidateDialog}
          onClose={() => setCandidateDialog(false)}
          transition={Transition}
        >
          <CandidateDetail candidate={selectedApplicant} />
        </CustomDialog>
      )}
      {selectedJob && (
        <JobDetailDialog
          open={jobDialog}
          handleClose={() => setJobDialog(false)}
          jobPosting={selectedJob}
        />
      )}
      {mailStatus && (
        <ResponseSnackbar
          open={mailStatus}
          message={"Mail sent successfully"}
          onClose={() => setMailStatus(false)}
        />
      )}
      {mailOpen && (
        <FullScreenDialog
          title={"Send Mail"}
          open={mailOpen}
          handleClose={() => setMailOpen(false)}
        >
          <SendMailForm
            to={candidateMail}
            onSent={() => {
              setMailStatus(true);
              setMailOpen(false);
            }}
          />
        </FullScreenDialog>
      )}
      {user.role === "SA" && allApplicants !== undefined && (
        <AppliedCandidateTable
          data={allApplicants.data}
          columnSort={["Applied On"]}
          count={allApplicants.count}
          sortOrder={columnFilters.sortOrder}
          rowsPerPage={columnFilters.limit}
          currentPage={columnFilters.page}
          selectedPosts={columnFilters.post.split(",")}
          selectedStatus={columnFilters.status.split(",")}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortOrder={changeSortOrder}
          handleChangeColumnFilter={handleColumnFilterChange}
          columnHeaders={SATableHeader}
          actions={TableActions()}
        />
      )}
      {user.role === "company" && applicantsToCompany !== undefined && (
        <CompanyCandidateTable
          data={companyApplicants.rows}
          columnSort={["Applied On"]}
          count={companyApplicants.count}
          sortOrder={columnFilters.sortOrder}
          rowsPerPage={columnFilters.limit}
          currentPage={columnFilters.page}
          selectedPosts={columnFilters.post.split(",")}
          selectedStatus={columnFilters.status.split(",")}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortOrder={changeSortOrder}
          handleChangeColumnFilter={handleColumnFilterChange}
          columnHeaders={CompanyTableHeader}
          actions={TableActions()}
        />
      )}
    </ListWrapper>
  );
}

export default AppliedCandidate;
