import { useRef, useState } from "react";
import QuillEditor from "./QuillEditor";
import { Typography } from "@mui/material";

function ReactQuillEditor({ defaultValue, onTextChange, error }) {
  const [range, setRange] = useState();
  const [lastChange, setLastChange] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const quillRef = useRef();
  return (
    <>
      {error.error && (
        <Typography color={"error"} mb={1}>
          {error.message}
        </Typography>
      )}
      <QuillEditor
        ref={quillRef}
        readOnly={false}
        defaultValue={defaultValue}
        onSelectionChange={setRange}
        onTextChange={setLastChange}
        onChange={onTextChange}
      />
    </>
  );
}

export default ReactQuillEditor;
