import { useMemo } from "react";
import {
  Box,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton
} from "@mui/material";
import TableAction from "app/components/TableAction";
import StatusDropdown from "./StatusDropdown";
import SortButton from "app/views/jobs/shared/SortButton";
import ShareStatusDropdown from "./ShareStatusDropdown";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { FilterList } from "@mui/icons-material";
import PopoverContent from "./PopoverContent";
import useAuth from "app/hooks/useAuth";
import StatusText from "app/components/StatusText";
// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  "& thead": {
    "& tr": { "& th": { paddingLeft: 10, paddingRight: 10 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  },
  width: "100%",
  minWidth: "max-content",
  tableLayout: "auto"
}));

const StatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Short-listed", value: "shortlisted" },
  { label: "Accepted (Hired)", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];

const Status = ({ role, status, companyName, rowId, candidateId, candidateName, jobTitle }) => {
  return role === "company" || role === "SA" ? (
    <StatusDropdown
      status={status}
      applicationId={rowId}
      candidateId={candidateId}
      candidateName={candidateName}
      companyName={companyName}
      jobTitle={jobTitle}
    />
  ) : (
    <StatusText status={status} />
  );
};

const TableCellWrapper = ({ isFirst = false, children }) => {
  return <TableCell align={`${isFirst ? "left" : "center"}`}>{children}</TableCell>;
};

function AppliedCandidateTable({
  data,
  count,
  sortOrder,
  columnHeaders,
  columnSort,
  currentPage,
  rowsPerPage,
  selectedPosts,
  selectedStatus,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortOrder,
  handleChangeColumnFilter,
  actions
}) {
  const { user } = useAuth();
  const [postAnchor, setPostAnchor] = useState();
  const [statusAnchor, setStatusAnchor] = useState();

  function closePopAnchor() {
    setPostAnchor(null);
  }

  const JobPostOptions = useMemo(() => {
    const jobPosts = [...new Set(data.map(({ jp_title }) => jp_title))]; // only unique job post titles
    return jobPosts.map((job) => ({ label: job, value: job }));
  }, [data]);

  const handleFilterClick = (event) => {
    if (event.target.id === "Job Title") {
      setPostAnchor(event.target);
    }
    if (event.target.id === "Status") {
      setStatusAnchor(event.target);
    }
  };

  return (
    <Box width={"100%"} overflow={"auto"}>
      <StyledTable>
        <TableHead>
          <TableRow>
            {columnHeaders.map((header, index) => {
              const thStyle = header === "Profile" ? { width: "100px" } : {};
              return (
                <TableCell key={`${header}_${index}`} align={"center"} style={thStyle}>
                  {header}
                  {/* {columnSort.includes(header) && (
                    <SortButton
                      sortOrder={sortOrder}
                      identifier={header}
                      onSort={handleSortOrder}
                    />
                  )} */}
                  {header === "Status" && (
                    <IconButton id={header} onClick={handleFilterClick}>
                      <FilterList id={header} />
                    </IconButton>
                  )}
                  <Popover
                    open={Boolean(statusAnchor)}
                    anchorEl={statusAnchor}
                    onClose={() => setStatusAnchor(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  >
                    <PopoverContent
                      value={selectedStatus}
                      options={StatusOptions}
                      closePopup={() => setStatusAnchor(null)}
                      onChange={(event) => {
                        handleChangeColumnFilter(
                          event.target.value,
                          "status",
                          event.target.checked
                        );
                      }}
                      title={"Filter by Status"}
                    />
                  </Popover>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage).map((currentRow, index) => {
            return (
              <TableRow key={currentRow.jp_id}>
                {/** Created At*/}
                <TableCellWrapper>{currentRow.jp_created_at}</TableCellWrapper>
                {/** Candidate Name */}
                <TableCellWrapper>{currentRow.jp_candidate_name}</TableCellWrapper>
                {/** Job Title */}
                <TableCellWrapper>{currentRow.jp_title}</TableCellWrapper>
                {/** Current CTC */}
                <TableCellWrapper>{currentRow.jp_current_ctc}</TableCellWrapper>
                {/** Notice Period */}
                <TableCellWrapper>{currentRow.jp_notice_period}</TableCellWrapper>
                {/** Status */}
                <TableCellWrapper>
                  {Status({
                    role: user.role,
                    status: currentRow.jp_status,
                    rowId: currentRow.jp_id,
                    candidateId: currentRow.jp_candidate_id,
                    jobTitle: currentRow.jp_title,
                    candidateName: currentRow.jp_candidate_name,
                    companyName: user.name
                  })}
                </TableCellWrapper>
                {actions &&
                  actions.map((action, index) => (
                    <TableCell align="center" key={`${action}_${index}`}>
                      <TableAction
                        key={`${currentRow.jp_id}_${index}`}
                        icon={action.icon}
                        action={() => {
                          action.action(currentRow);
                        }}
                      />
                    </TableCell>
                  ))}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={currentPage}
        component="div"
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default AppliedCandidateTable;
