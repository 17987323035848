import CustomDialog from "app/components/CustomDialog";
import { DialogContentText, TextField, DialogActions, Button, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
function AddNewSkillDialog({ open, onClose, addFn, loading }) {
  return (
    <CustomDialog
      open={open}
      dialogTitle={"Add New Skill"}
      fullWidth={true}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const skill = formJson.jp_skills;
          addFn({ jp_skills: skill });
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogContentText>
          Add new skill separated by a comma e.g Skill1, Skill2, Skill3 etc
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="jp_skills"
          name="jp_skills"
          label="Skills"
          sx={{ mt: 2 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "gray" }} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={loading}>
          Add New Skill
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
}

export default AddNewSkillDialog;
