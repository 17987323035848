import { Box, styled, Typography } from "@mui/material";
import { useState } from "react";

const PointerSpan = styled("span")({
  cursor: "pointer"
});
function ReadMoreText({
  className,
  text,
  maxChars,
  readMoreText = "read more",
  readLessText = "read less",
  ...rest
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasReadMore = text ? (text.length > maxChars ? true : false) : false;
  return (
    <Box>
      {text && (isExpanded || !hasReadMore) && (
        <Typography variant="body2" {...rest}>
          {text}{" "}
          {hasReadMore && !!isExpanded && (
            <PointerSpan onClick={() => setIsExpanded(false)}>
              <strong>{readLessText}</strong>
            </PointerSpan>
          )}
        </Typography>
      )}
      {text && !isExpanded && !!hasReadMore && (
        <Typography variant="body2" {...rest}>
          {text.slice(0, maxChars)}...{" "}
          <PointerSpan onClick={() => setIsExpanded(true)}>
            <strong>{readMoreText}</strong>
          </PointerSpan>
        </Typography>
      )}
    </Box>
  );
}

export default ReadMoreText;
