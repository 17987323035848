import { useMutation, useQuery } from "@tanstack/react-query";
import DeletedListWrapper from "./DeletedListWrapper";
import { useState } from "react";
import { getDeletedCandidates, restoreCandidate } from "app/api/candidate_api";
import { Restore } from "@mui/icons-material";
import TrashWrapper from "./shared/TrashWrapper";

const DeleteCandidateHeaders = ["Name", "Contact No", "isActive", "State", "Restore"];

function DeletedCandidates(props) {
  const [columnFilters, setColumnFilters] = useState({
    sortOrder: "DESC",
    column: undefined,
    limit: 5,
    offset: 0,
    currentPage: 0
  });
  const [responseState, setResponseState] = useState({
    error: false,
    success: false,
    message: ""
  });

  const {
    mutate: restore,
    isPending: isRestoring,
    isSuccess: isRestoreSuccess
  } = useMutation({
    mutationFn: (params) => restoreCandidate(params.id),
    onSuccess: () => {
      setResponseState((prev) => ({
        ...prev,
        success: true,
        message: "Candidate restored successfully"
      }));
    },
    onError: () => {
      setResponseState((prev) => ({
        ...prev,
        error: true,
        message: "Candidate restore failed"
      }));
    }
  });

  function handleChangeRowsPerPage(event) {
    setColumnFilters((prev) => ({ ...prev, limit: +event.target.value, currentPage: 0 }));
  }

  const handleChangePage = (_, newPage) => {
    setColumnFilters((prev) => ({ ...prev, currentPage: newPage, offset: newPage * prev.limit }));
  };

  function handleRestoreCandidate(selectedRow) {
    restore({ id: selectedRow.jp_id });
  }

  const { data: deletedCandidates, isFetched } = useQuery({
    queryKey: [
      "deletedCompanies",
      isRestoreSuccess,
      columnFilters.sortOrder,
      columnFilters.column,
      columnFilters.limit,
      columnFilters.offset
    ],
    queryFn: () =>
      getDeletedCandidates(
        columnFilters.sortOrder,
        columnFilters.column,
        columnFilters.limit,
        columnFilters.offset
      )
  });

  const content = isFetched ? (
    <DeletedListWrapper
      data={deletedCandidates.rows.map(
        ({ jp_id, jp_candidate_name, jp_contactno, jp_active, jp_state }) => ({
          jp_id,
          jp_name: jp_candidate_name,
          jp_contactno,
          jp_active: jp_active ? "True" : "False",
          jp_state
        })
      )}
      count={deletedCandidates.count}
      tableHeaders={DeleteCandidateHeaders}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      columnSort={["Company"]}
      columnFilters={columnFilters}
      actions={[{ icon: <Restore />, action: handleRestoreCandidate }]}
    />
  ) : (
    <span>Loading...</span>
  );

  const closeResponseSnackBar = () =>
    setResponseState({ error: false, success: false, message: "" });
  return (
    <TrashWrapper
      showProgress={isRestoring ? true : false}
      responseState={responseState}
      onCloseSnackbar={closeResponseSnackBar}
    >
      {content}
    </TrashWrapper>
  );
}

export default DeletedCandidates;
