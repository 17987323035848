import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, styled } from "@mui/material";

const AutoComplete = styled(Autocomplete)(() => ({ width: 300, marginBottom: "16px" }));
function FormAutocomplete({
  name,
  label,
  values,
  value = undefined,
  defaultValue,
  control,
  helperText,
  filterOptions,
  onInputChange = () => {},
  onChangeInput = () => {},
  sxValues,
  disabled = false,
  freeSolo = false,
  autoSelect = false,
  inputProps,
  ...rest
}) {
  let preloadedValue = undefined;
  if (defaultValue) {
    preloadedValue = values.find((value) => value.label === defaultValue);
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={preloadedValue}
      rules={{ required: helperText }}
      render={({ field: { onChange }, fieldState: { error }, InputProps }) => (
        <AutoComplete
          defaultValue={preloadedValue}
          value={value}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          freeSolo={freeSolo}
          autoSelect={autoSelect}
          disabled={disabled}
          filterOptions={filterOptions}
          onInputChange={(e, data) => {
            onInputChange(data);
          }}
          onChange={(event, value) => {
            onChangeInput(value);
            onChange(value);
          }}
          sx={{ ...sxValues }}
          options={!values ? [{ label: "Loading...", id: 0 }] : values}
          slotProps={{
            input: {
              ...InputProps,
              type: "search"
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              label={label}
              InputProps={{
                ...params.InputProps,
                ...inputProps
              }}
              {...rest}
              variant="outlined"
            />
          )}
        />
      )}
    />
  );
}

export default FormAutocomplete;
