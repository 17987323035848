export const Stream = [
  { id: 1, label: "Science (PCM, PCB, PCMB etc)" },
  { id: 2, label: "Arts (History, Geography, Philosophy etc)" },
  { id: 3, label: "Commerce (Accountancy, Statistics, Business etc)" },
  { id: 4, label: "Vocational (Hospitality, Tourism, Retail etc)" }
];

export const CountryCodeMap = {
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  "​Andorra": "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua And Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  "​Azerbaijan": "AZ",
  "​Bahrain": "BH",
  "​Bangladesh": "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bonaire, Sint Eustatius and Saba": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  Congo: "CG",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  "Cote D'Ivoire (Ivory Coast)": "CI",
  Croatia: "HR",
  Cuba: "CU",
  Curaçao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  "Democratic Republic of the Congo": "CD",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  "East Timor": "TL",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  "Fiji Islands": "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  "Guernsey and Alderney": "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  Honduras: "HN",
  "Hong Kong S.A.R.": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Kosovo: "XK",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  "​Lithuania": "LT",
  Luxembourg: "LU",
  "Macau S.A.R.": "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Man (Isle of)": "IM",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "North Korea": "KP",
  "Northern Mariana Islands": "MP",
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory Occupied": "PS",
  Panama: "PA",
  "Papua new Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  "Pitcairn Island": "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  Reunion: "RE",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Helena": "SH",
  "Saint Kitts And Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent And The Grenadines": "VC",
  "Saint-Barthelemy": "BL",
  "Saint-Martin (French part)": "MF",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten (Dutch part)": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia": "GS",
  "South Korea": "KR",
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard And Jan Mayen Islands": "SJ",
  Swaziland: "SZ",
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  "The Bahamas": "BS",
  "The Gambia": "GM",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad And Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks And Caicos Islands": "TC",
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United Kingdom": "GB",
  "United States": "US",
  "United States Minor Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  "Vatican City State (Holy See)": "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  "Virgin Islands (British)": "VG",
  "Virgin Islands (US)": "VI",
  "Wallis And Futuna Islands": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW"
};

export const Skills = [
  ,
  { id: 0, label: "Android Development" },
  { id: 1, label: "Supply Chain Management" },
  { id: 2, label: "UX Designer" },
  { id: 3, label: "React Developer" },
  { id: 4, label: "Customer Service" },
  { id: 5, label: "Sales Support" },
  { id: 6, label: "Project Management" },
  { id: 7, label: "Content Writing" },
  { id: 8, label: "Cloud Computing" },
  { id: 9, label: "AI/ML Developer" },
  { id: 10, label: "Blockchain Developer" },
  { id: 11, label: "3D Artist" },
  { id: 12, label: "iOS Developer" },
  { id: 13, label: "SEO Developer" },
  { id: 14, label: "Video Game Developer" },
  { id: 15, label: "Dev Ops" },
  { id: 16, label: "CAD Design" },
  { id: 17, label: "Research" },
  { id: 18, label: "Linux Administrator" },
  { id: 19, label: "Full Stack Software Developer" },
  { id: 21, label: "Cost Accounting" },
  { id: 22, label: "Data Scientist" },
  { id: 23, label: "Flutter Developer" },
  { id: 24, label: "System's Engineer" },
  { id: 25, label: "Network Engineer" },
  { id: 26, label: "2D Artist" },
  { id: 27, label: "Writer" },
  { id: 28, label: "Hindi" },
  { id: 29, label: "Chinese" },
  { id: 30, label: "French" },
  { id: 31, label: "AWS Engineer" },
  { id: 32, label: "Microsoft Azure Engineer" },
  { id: 33, label: "VFX Creative Director" },
  { id: 34, label: "Narrative Designer" },
  { id: 35, label: "Software Tester" },
  { id: 36, label: "Automation Engineer" }
];

export const States = [
  { id: 0, label: "ANDAMAN AND NICOBAR ISLANDS" },
  { id: 1, label: "ANDHRA PRADESH" },
  { id: 2, label: "ARUNACHAL PRADESH" },
  { id: 3, label: "ASSAM" },
  { id: 4, label: "BIHAR" },
  { id: 5, label: "CHANDIGARH" },
  { id: 6, label: "CHHATTISGARH" },
  { id: 7, label: "DADRA AND NAGAR HAVELI" },
  { id: 8, label: "DAMAN AND DIU" },
  { id: 9, label: "DELHI" },
  { id: 10, label: "GOA" },
  { id: 11, label: "GUJARAT" },
  { id: 12, label: "HARYANA" },
  { id: 13, label: "HIMACHAL PRADESH" },
  { id: 14, label: "JAMMU AND KASHMIR" },
  { id: 15, label: "JHARKHAND" },
  { id: 16, label: "KARNATAKA" },
  { id: 17, label: "KERALA" },
  { id: 18, label: "LADAKH" },
  { id: 19, label: "LAKSHADWEEP" },
  { id: 20, label: "MADHYA PRADESH" },
  { id: 21, label: "MAHARASHTRA" },
  { id: 22, label: "MANIPUR" },
  { id: 23, label: "MEGHALAYA" },
  { id: 24, label: "MIZORAM" },
  { id: 25, label: "NAGALAND" },
  { id: 26, label: "ODISHA" },
  { id: 27, label: "PUDUCHERRY" },
  { id: 28, label: "PUNJAB" },
  { id: 29, label: "RAJASTHAN" },
  { id: 30, label: "SIKKIM" },
  { id: 31, label: "TAMIL NADU" },
  { id: 32, label: "TELANGANA" },
  { id: 33, label: "TRIPURA" },
  { id: 34, label: "UTTARAKHAND" },
  { id: 35, label: "UTTAR PRADESH" },
  { id: 36, label: "WEST BENGAL" }
];
