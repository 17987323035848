import React from "react";
import CustomDialog from "app/components/CustomDialog";
import {
  TextField,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useVerifyOTP } from "app/hooks/otp/useVerifyOTP";
import { useState } from "react";
import useUpdateCandidate from "app/hooks/candidate/useUpdateCandidate";
import useAuth from "app/hooks/useAuth";
import useUpdateEmailStatus from "app/hooks/candidate/useUpdateEmailStatus";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
function VerifyEmailDialog({ open, onClose, email, loading }) {
  const { user } = useAuth();
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState({ error: false, message: "" });
  const [verifyError, setVerifyError] = useState({ error: false, message: "" });
  const queryClient = useQueryClient();

  const onUpdateSuccess = () => {
    setOTP("");
    setOTPError({ error: false, message: "" });
    onClose();
    queryClient.invalidateQueries({ queryKey: ["candidate", user.id] });
  };
  const onUpdateError = (error) => {
    const errorResData = error.response.data;
    if (errorResData.message) {
      setVerifyError({ error: true, message: errorResData.message });
      setOTPError({ error: true, message: "" });
    } else {
      setVerifyError({
        error: true,
        message: "Could not verify email at the moment. Please try again later. "
      });
    }
  };

  const { mutate: updateEmailStatus } = useUpdateEmailStatus({
    onSuccess: onUpdateSuccess,
    onError: onUpdateError
  });

  const handleVerifySuccess = () => {
    updateEmailStatus({ id: user.id, status: 1, email });
  };
  const handleVerifyError = (error) => {
    setOTPError({ error: true, message: error.response.data.message });
  };

  const { mutate: verifyEmail } = useVerifyOTP({
    onSuccess: handleVerifySuccess,
    onError: handleVerifyError
  });

  const handleVerifyEmail = () => {
    if (otp === null || otp === "") {
      setOTPError({ error: true, message: "OTP field is empty" });
    } else {
      verifyEmail({ email, otp });
    }
  };

  return (
    <CustomDialog
      open={open}
      dialogTitle={"Verify Email"}
      fullWidth={true}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onClose();
        }
      }}
    >
      <DialogContent>
        <DialogContentText>Please enter the OTP sent to the email id: {email} </DialogContentText>
        {verifyError.error && <Typography color={"error"}>{verifyError.message}</Typography>}
        <TextField
          autoFocus
          required
          margin="dense"
          id="email_otp"
          value={otp}
          onChange={(event) => setOTP(event.target.value)}
          name="otp"
          error={otpError.error}
          helperText={otpError.message}
          label="6 digit OTP"
          sx={{ mt: 2 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "gray" }}
          onClick={() => {
            setOTP("");
            setOTPError({ error: false, message: "" });
            setVerifyError({ error: false, message: "" });
            onClose();
          }}
        >
          Cancel
        </Button>
        <LoadingButton onClick={handleVerifyEmail} loading={loading} variant="contained">
          Verify
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
}

export default VerifyEmailDialog;
