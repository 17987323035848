import React from "react";
import { Accordion, Box, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
function AccordianFilter({ title, filter, defaultExpanded }) {
  return (
    <Accordion sx={{ boxShadow: "none" }} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ArrowDropDown />}>
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>{filter || ""}</Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordianFilter;
